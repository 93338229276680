/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-calendar {
    .btn-calendar {

        &__svg-calendar {
            visibility: hidden;
            opacity: 0;
        }

        &__X {
            visibility: visible;
            opacity: 1;
        }

        &__container--close {
            visibility: visible;
        }
    }
    .overlay-calendar {
        pointer-events: all;
        visibility: visible;

        &__container {
            opacity: 1;
            pointer-events: all;
        }

        &__content {
            opacity: 1;
            transform: none;
        }
    }
    .overlay-calendar__close-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-calendar.closing-overlay-calendar {

    .btn-calendar {

        &__svg-calendar {
            visibility: visible;
            opacity: 1;
        }

        &__X {
            visibility: hidden;
            opacity: 0;
        }

        &__container--close {
            visibility: hidden;
        }
    }

    .overlay-calendar {
        &__container {
            opacity: 0;
        }
        
        &__content {
            opacity: 0;
            transform: translateY(100px);
        }
    }
}
.result-block {
    padding: 0 140px 50px 25px;
    margin-bottom: 50px;
    border-bottom: solid 1px rgba($color1, $alpha: 0.2);

    @media screen and (max-width: 700px) {
        padding-right: 100px;
    }

    @media screen and (max-width: 500px) {
        padding-right: 25px;
    }

    &__page {
        font-family: $font1;
        font-size: 14px;
        line-height: 1.7;
        font-weight: 700;
        color: $color1;
        text-transform: uppercase; 
        margin-bottom: 5px;
    }

    &__result {
        background: $color3;
        padding: 0 5px;
    }

    mark {
        font-style: normal;
        background: $color3;
        color: $color1;
        padding: 0 5px;
    }

    &__title {
        font-family: $font1;
        font-size: 35px;
        line-height: 1.5;
        font-weight: 700;
        color: $color1;
        margin-bottom: 10px;

        @media screen and (max-width: 500px) {
            font-size: 25px;
        }
    }

    &__text {
        font-family: $font1;
        font-size: 18px;
        line-height: 1.7;
        color: $color1;
        margin-bottom: 50px;
    }
}

.result-block:first-child {
    padding-top: 45px;
    border-top: solid 1px rgba($color1, $alpha: 0.2);
}
.pagination {
    &__content {
        border-top: 1px solid $color1;
        border-bottom: 1px solid $color1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @extend .x-padding-70;
    }

    &__previous-page, &__next-page {
        display: flex;
        align-items: center;
        padding: 35px 0;
    }

    &__previous, &__next {
        font-size: 14px;
        font-family: $font1;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 2px;
        color: $color1; 
        text-transform: uppercase;

        @media screen and (max-width: 500px) {
            font-size: 12px;
        }

        @media screen and (max-width: 400px) {
            display: none;
        }
    }

    &__previous {
        margin-left: 40px;
    }

    &__last-page {
        opacity: 0.5;
    }

    &__next {
        margin-right: 40px;
    }

    &__numbers {
        display: flex;
    }

    &__number {
        font-size: 20px;
        font-family: $font1;
        font-weight: 700;
        line-height: 1;
        color: $color1; 
        background: #fff;
        transition: background 300ms $easeInOutCubic;
        padding: 35px 27px;

        &:hover {
            background: $color6;
        }

        @media screen and (max-width: 768px) {
            font-size: 18px;
            padding: 20px;
        }
    }

    &__number-dots {
        padding: 30px 20px 10px 20px;
    }

    &__left-arrow, &__right-arrow {
        width: 22px;
        height: 16px;
        fill: none;
        stroke: $color1;
        stroke-width: 1.5px;
        cursor: pointer;
    }

    &__right-arrow {
        transform: rotate(180deg);
    }

    &__svg-arrow {
        width: 100%;
        height: 100%;
        transition: transform 300ms $easeInOutCubic;

        &:hover {
            transform: translateX(-5px);
        }
    }

    &__svg-arrow--left, &__svg-arrow--right {
        &:hover {
            transform: none;
        }
    }

    &__previous-page:hover &__svg-arrow--left {
        transform: translateX(-5px);
    }

    &__next-page:hover &__svg-arrow--right {
        transform: translateX(-5px);
    }
}
.dynamic {
    > :first-child { margin-top: 0 !important; }
    > :last-child { margin-bottom: 0 !important; }

    h2 {
        @include large-title();
        margin: 70px 0 30px;

        @media screen and (max-width: 1024px) { margin: 50px 0 25px; }
        @media screen and (max-width: 768px)  { margin: 40px 0 20px; }
        @media screen and (max-width: 500px)  { margin: 30px 0 15px; }
    }

    h3 {
        @include medium-title();
        margin: 50px 0 35px;

        @media screen and (max-width: 1024px) { margin: 30px 0 20px; }
        @media screen and (max-width: 768px)  { margin: 25px 0 15px; }
    }

    h4 {
        @include small-title();
        margin: 30px 0 15px;

        @media screen and (max-width: 1024px) { margin: 25px 0 10px; }
        @media screen and (max-width: 768px)  { margin: 20px 0 10px; }
    }

    p {
        margin: 15px 0;
        @include paragraph();

        @media screen and (max-width: 768px) { margin: 10px 0; }
    }

    strong {
        font-style: normal;
        font-weight: 700;
    }

    em {
        font-style: italic;
        font-weight: normal;
    }

    strong {
        em {
            font-style: italic;
            font-weight: 700;
        }
    }

    pre {
        margin: 10px 0;
        font: rem(13)/1.5 $font1;
        color: $color9;
        white-space: normal;
    }

    blockquote {
        position: relative;

        p {
            margin: 30px 0;
            font-family: $font1;
            font-weight: 700;
            line-height: 1.5;
            color: $color4;
            @include responsiveFontSize(22, 18);
        }
    }

    a:not(.link):not(.btn):not(:has(img)) {
        display: inline;
        @include paragraph();
        padding-bottom: 2px;
        box-shadow: inset 0px -2px 0 $color3;
        transition: box-shadow 300ms $easeInOutCubic;

        &:hover { box-shadow: inset 0px -20px 0 $color3; }
    }

    .btn {
        margin: 25px 0;
    }

    ol,
    ul{
        margin-bottom: 15px;
        li{
            padding-bottom: 10px;
            font-weight: normal;
            @include paragraph();
            color: $color1;
            b,strong{
                font-weight: 500;
            }

            p {
                font-weight: normal;
                @include paragraph();
                color: $color1;
                margin: 0;
            }
        }
    }

    ol{
        list-style: none;
        counter-reset: li;
        li{
            position: relative;
            padding-left: 50px;
            padding-top: 10px;
            padding-bottom: 10px;
            &:last-child{ margin-bottom: 0; }
            &::before{
                content: counter(li);
                counter-increment: li;
                color: $color1;
                font-size: 16px;
                font-weight: 700;
                font-family: $font1;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: solid 1px rgba($color1, $alpha: 0.3);
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;

            }
        }
        ol{
            padding-top: 10px;
            margin-bottom: 0;
            padding-left: 0;
            li{
                border-bottom: 0;
                padding-left: 50px;
                padding-top: 0;
                padding-bottom: 10px;
            }
        }

        ul {
            padding-top: 15px;
            
            li {
                padding-top: 0;
                &::before{ background: transparent; top: 0.9em; }
            }

            ul {
                padding-top: 15px;
            }
        }
    }

    ul {
        li{
            position: relative;
            padding-left: 20px;
            &::before{
                content: '';
                width: 4px;
                height: 4px;
                border: 1px solid $color2;
                background: $color2;
                position: absolute;
                left: 0;
                top: 0.8em;
                transform: translate(0,-50%);
                border-radius: 50%;
            }
            &:last-child{
                padding-bottom: 0;
            }
            ul{
                padding-top: 10px;
                margin-bottom: 0;
                padding-left: 16px;
                li{
                    &::before{ background: transparent; }
                    &:last-child{ padding-bottom: 0; }
                    ul{
                        li{
                            &::before{ background: $color2; }
                        }
                    }
                }
            }
        }

        ol {
            list-style: none;
            counter-reset: li;
            li{
                position: relative;
                padding-left: 50px;
                padding-top: 10px;
                padding-bottom: 10px;
                &:last-child{ margin-bottom: 0; }
                &::before{
                    content: counter(li);
                    counter-increment: li;
                    color: $color1;
                    font-size: 16px;
                    font-weight: 700;
                    font-family: $font1;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border: solid 1px rgba($color1, $alpha: 0.3);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    left: 0;
                    top: 1.5em;
                }
            }
        }
    }

    .tableWrapper {
        width: 100%;
        overflow-x: auto;
    }

    table {
        width: auto;
        white-space: nowrap;
        overflow-x: hidden;
        table-layout: fixed;
        margin: 30px 0 50px;

        @media screen and (max-width: 1024px) { margin: 25px 0 40px; }
        @media screen and (max-width: 768px)  { margin: 20px 0 30px; }
        @media screen and (max-width: 500px)  { margin: 15px 0 25px; }

        thead {
            tr th, tr td {
                border: 1px solid rgba(#fff, 0.3);
                border-bottom: none;
                padding: 20px 30px;
                color: #fff;
                background: $color4;
                white-space: normal;
                text-align: left;

                @media screen and (max-width: 768px) { font-size: rem(16) }

                &, p, h4, h3, h2 {
                    font: 700 rem(18)/1.5 $font1;

                    @media screen and (max-width: 768px) { font-size: rem(16) }
                }
            }
        }

        tbody {
            tr{
                td,th{
                    &.style1{
                        background: $color6;
                    }
                }
            }
            tr td:first-child, tr th:first-child { border-left: 1px solid rgba($color1, 0.2); }
            tr:last-child td, tr:last-child th { border-bottom: 1px solid rgba($color1, 0.2); }
            tr td, tr th {
                padding: 20px 30px;
                margin: 0;
                font: rem(18)/1.5 $font1;
                font-weight: normal;
                color: $color1;
                white-space: normal;
                border-right: 1px solid rgba($color1, 0.2);
                border-top: 1px solid rgba($color1, 0.2);
                vertical-align: middle !important;
                background: #fff;

                @media screen and (max-width: 768px) { font-size: rem(16) }

                > :first-child { margin-top: 0 !important; }
                > :last-child { margin-bottom: 0 !important; }
                &:last-child { border-bottom: 1px solid rgba($color1, 0.2);}

                p {
                    font: rem(18)/1.5 $font1;
                    color: $color1;

                    @media screen and (max-width: 768px) { font-size: rem(16); }
                }
            }

            tr:only-child td:only-child {
                padding: 40px;
                border: 1px solid rgba($color1, 0.2);
                width: 100%; 
                background:#eff2f6; 
                border: none;

                @media screen and (max-width: 1024px) { padding: 30px; }
                @media screen and (max-width: 768px)  { padding: 25px; }
            }
        }
    }
}
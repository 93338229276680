/*** ----- SELECTORS ----- ***/
$scrollfire: '[data-scrollfire], [data-multiscrollfire]';
$fire: '&[data-scrollfire="fire"], &[data-multiscrollfire="fire"]';

/*** ----- FADE ----- ***/
[data-fade] {
    opacity: 0;
    transition: opacity 500ms;
    #{$fire} { opacity: 1; transform: none; }
}

/*** ----- FADE UP ----- ***/
[data-fadeup] {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 500ms, transform 500ms $easeOutQuart;

    #{$fire} { opacity: 1; transform: none; }
}

/*** ----- PICTURE ----- ***/
[data-picture] {
    &:after { content: ''; position: absolute; top: 0; right: 0; bottom: 0; left: 0; }

    &[data-color="darkBlue"]:after { background: $color1; }
    &[data-color="green"   ]:after { background: $color2; }
    &[data-color="white"   ]:after { background: #fff;  }

    &[data-direction="top"]:after    { top: 0%;    transition: top    1100ms $easeInOutQuart }
    &[data-direction="right"]:after  { right: 0%;  transition: right  1100ms $easeInOutQuart }
    &[data-direction="bottom"]:after { bottom: 0%; transition: bottom 1100ms $easeInOutQuart }
    &[data-direction="left"]:after   { left: 0%;   transition: left   1100ms $easeInOutQuart }

    &[data-scale] img { transform: scale(1.1); transition: transform 1100ms $easeInOutQuart }

    #{$fire} {
        img { transform: none; }
        &[data-direction="top"]:after    { top:  100%;   }
        &[data-direction="right"]:after  { right: 100%;  }
        &[data-direction="bottom"]:after { bottom: 100%; }
        &[data-direction="left"]:after   { left: 100%;   }
    }
}


/*** ----- DELAY ----- ***/
#{$scrollfire} {
    transition-delay: 0ms;
    &[data-delay1], &[data-delay1]:after { transition-delay: 100ms; }
    &[data-delay2], &[data-delay2]:after { transition-delay: 200ms; }
    &[data-delay3], &[data-delay3]:after { transition-delay: 300ms; }
    &[data-delay4], &[data-delay4]:after { transition-delay: 400ms; }
    &[data-delay5], &[data-delay5]:after { transition-delay: 500ms; }
    &[data-delay6], &[data-delay6]:after { transition-delay: 600ms; }
}


/*** ----- DURING LOADING AND TRANSITION ----- ***/
.showLanding       { #{$scrollfire} { transition-delay: 0ms !important;   }}
.showTransition    { #{$scrollfire} { transition-delay: 400ms !important; }}
.showTransition,
.showLanding       {
    [data-fade]    { opacity: 0 !important; }
    [data-fadeup]  { opacity: 0 !important; transform: translateY(100px) !important; }
    [data-picture] {
        &[data-scale] img                { transform: scale(1.1); }
        &[data-direction="top"   ]:after { top:    0% !important; }
        &[data-direction="right" ]:after { right:  0% !important; }
        &[data-direction="bottom"]:after { bottom: 0% !important; }
        &[data-direction="left"  ]:after { left:   0% !important; }
    }
}
.overlay-dropdown {
    position: absolute;
    width: 100%;
    top: var(--header-height);
    bottom: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 20;

    @media screen and (max-width: 1024px), screen and (max-height: 600px) { display: none; }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0);
        transition: background 800ms;
        z-index: -1;
    }
    
    &__container {
        position: absolute;
        width: 100%;
        height: 0px;
        top: 0;
        left: 0;
        background: #fff;
        overflow: hidden;
    }
    
    &__container:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: 0;
        left: 0;
        background: rgba($color1, 0);
        transition: background 300ms;
    }
    
    &__section {
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;
        flex-wrap: nowrap;
        visibility: hidden;
        opacity: 0;
        max-height: calc(100vh - 140px);
        overflow-y: auto;
		overflow-x: hidden;
        scrollbar-width: none;
        @extend .x-padding-160;

        @media screen and (max-width: 1400px) { padding: 0; }

        &::-webkit-scrollbar { display: none; }
    }

    &__sub-section {
        width: 35%;
        height: 100%;
        padding: 70px 50px;
        text-align: center;

        @media screen and (max-width: 1400px) { padding: 70px 80px; }
        @media screen and (max-width: 1300px) { padding: 60px; }
        @media screen and (max-width: 1200px) { padding: 50px 40px; }
    }

    &__sub-section-container {
        display: inline-block;
        text-align: left;
        transform: translateY(100px);
    }

    &__sub-section-title {
        margin-bottom: 30px;
        @extend .small-title;
        font-weight: 500;

        @media screen and (max-width: 1200px) { margin-bottom: 20px; }
    }

    &__list {

    }

    &__list-item {
        &:not(:last-child) {
            margin-bottom: 5px;

        }
    }

    &__link {
        font: rem(18)/1.5 $font1;
        color: $color1;
        transition: opacity 300ms;
    }
    &__link:hover {
        opacity: 0.5;
    }
}
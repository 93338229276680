.calendar  {
    width: 680px;
    height: 100%;
    padding: 0 60px;
    background: $color1;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.datepicker {
    position: relative;
    width: 100%;
    direction: ltr;
}

.datepicker-rtl {
    direction: rtl;
}

.datepicker-rtl.dropdown-menu {
    left: auto;
}

.datepicker-rtl table tr td span {
    float: right;
}

.datepicker-dropdown {
    top: 0;
    left: 0;
}

.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #999;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
}

.datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
    right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
    right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
    top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
    top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-top:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff;
}

.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.datepicker {

    // GLOBAL --------
    table {
        width: 100%;
        table-layout: fixed;
        word-wrap: break-word;
    }
    // ---------------

    // HEAD ----------
    .datepicker-switch {
        transform: translateY(4px);
    }

    .datepicker-switch, .prev, .next {
        transition: opacity 300ms;
    }

    .prev, .next {
        width: 0; 
        font-size: 0;
        cursor: pointer;
        width: 0; 
        height: 0; 
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 12px solid #fff;
        display: inline-block;
        margin-left: 20px;
        transition: margin-left 300ms $easeInOutCubic;

        &.disabled {
            visibility: hidden;
        }
    }

    .datepicker-switch {
        width: calc(100% - 50px);
        font: 700 rem(30)/1 $font1;
        color: #fff;

        @media screen and (max-width: 425px) { font-size: rem(18);}
    }

    .prev {
        transform: rotate(90deg);

        &:hover {
            margin-left: 15px;
        }
    }

    .next {
        transform: rotate(-90deg);
        transition: margin-left 300ms $easeInOutCubic;

        &:hover {
            margin-left: 25px;
        }
    }
    // ---------------

    // DAYS ----------
    .dow {
        width: 38px;
        padding-top: 40px;
        padding-bottom: 10px;
        font: 700 rem(18)/1 $font1;
        font-weight: 700;
        color: #fff;
        text-align: center;
        cursor: default;

        @media screen and (max-height: 600px) {
            padding-top: 20px;
        }
    }
    // ---------------

    // DATES ---------
    .day {
        position: relative;
        width: 58px;
        height: 58px;
        font: 700 rem(18)/1 $font1;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        z-index: 1;  

        @media screen and (max-height: 700px) {
            height: 48px;
        }

        @media screen and (max-height: 600px) {
            height: 38px;
        }

        &:before {
            content: '';
            position: absolute;
            width: 50px;
            height: 50px;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            transition: background 300ms;
        }
        
        &.disabled, &.disabled:hover {
            background: none;
            cursor: default;

            &:after{
                display: none;
            }
        }

        &:not(.disabled) {

            &:after {
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                top: 21px;
                right: 9px;
                transform: translate(-50%, -50%);
                background-color: $color4;
                z-index: 1;
                border-radius: 50%;

                @media screen and (max-width: 768px) {
                    right: 6px;
                }

                @media screen and (max-height: 600px) {
                    top: 12px;
                }

                @media screen and (max-width: 400px) {
                    right: 2px;
                }
            }

            &:hover {

                &:before {
                    background: rgba($color: #fff, $alpha: 0.2);
                }
            }

            
        }
    }

    .old, .new {
        color: $color11;

        &:nth-child(5):after {
            background-color: $color4;
        }
        &:nth-child(1):after {
            background-color: $color4;
        }

        &.day.active {
            
            &:before {
                background: #fff !important;
            }
        }
        &.day.today {
            
            &:before {
                background: #fff !important;
            }
        }
    }

    .day.today {
        color: $color4;
        transition: color 300ms $easeInOutCubic;

        &:before {
            content: '';
            position: absolute;
            width: 14px;
            height: 2px;
            top: auto;
            bottom: 15px;
            left: 51%;
            background: $color4;
            transition: background 300ms $easeInOutCubic;
            border-radius: 0;
            z-index: 1;
            transform: translateX(-50%);

            @media screen and (max-height: 700px) {
                top: 35px;
            }
        }

        &:hover {
            
            &::after {
                background: rgba($color: #fff, $alpha: 0.2);
            }
        }
    }

    .day.active {
        cursor: default;
        color: $color4;

        &:before {
            background: #fff;
        }
        
        &:hover {
            &:before {
                background: #fff;
            }
        }
    }
    // ---------------

    // MONTHS --------
    .month, .year, .decade, .century {
        float: left;
        width: 25%;
        height: 30px;
        font: rem(12)/30px $font1;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;

        &:hover {
            background: #e9eaeb;
        }

        &.focused {
            color: #fff;
            background: $color1;
        }
    }
    // ---------------
}

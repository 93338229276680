.vitrine {
    &__container {
        padding: 130px 240px;
        background: $color6;

        @media screen and (max-width: 1500px) {
            padding: 130px 120px;
        }

        @media screen and (max-width: 1200px) {
            padding: 130px 100px;
        }

        @media screen and (max-width: 1024px) {
            padding: 130px 50px;
        }

        @media screen and (max-width: 768px) {
            padding: 100px 30px;
        }

        @media screen and (max-width: 600px) {
            padding: 80px 25px;
        }
    }

    &__content {
        min-height: 385px;
        display: flex;
        justify-content: center;
        position: relative;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            min-height: 0;
        }
    }

    &__picture {
        height: auto;
        overflow: hidden;
        flex: 1;
        position: relative;

        @media screen and (max-width: 768px) {
            width: 100%;
            height: 300px;
        }

        @media screen and (max-width: 500px) {
            width: 100%;
            height: 230px;
        }
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: transform 500ms $easeInOutQuart;

        @media screen and (max-width: 768px) {
            position: relative;
        }
    }

    &__content:hover &__img {
        transform: scale(1.1);
    }

    &__right-box {
        width: 400px;
        background: #fff;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__right-box-content {
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__infos {
        display: flex;
        flex-direction: column;
        padding: 75px 50px 110px 50px;

        @media screen and (max-width: 768px) {
            padding: 50px 50px 150px 50px;   
        }

        @media screen and (max-width: 500px) {
            padding: 30px 30px 100px 30px;   
        }
    }

    &__infos-title {
        font-family: $font1;
        font-size: 28px;
        line-height: 1.2;
        color: $color1; 
        margin-bottom: 30px;

        @media screen and (max-width: 768px) {
            font-size: 28px;
            margin-bottom: 20px;
        }
    }

    &__infos-txt {
        font-family: $font1;
        font-size: 16px;
        line-height: 1.4;
        color: $color1;
        max-width: 240px; 

        @media screen and (max-width: 768px) {
            font-size: 16px;
        }
    }

    &__icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: $color3;
        transform: rotate(-90deg);
        position: absolute;
        right: 40px;
        bottom: 40px;

        @media screen and (max-width: 500px) {
            width: 40px;
            height: 40px;
            right: 30px;
            bottom: 30px;
        }

        &::before {
            content: "";
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: $color3;
            transition: transform 300ms $easeInOutCubic;
            position: absolute;
            
            @media screen and (max-width: 500px) {
                width: 40px;
                height: 40px;
            }
        }
    }

    &__container :hover &__icon {
        &::before {
            transform: scale(1.1);
        }
    }

    &__icon-triangle {
        width: 0; 
        height: 0; 
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 12px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
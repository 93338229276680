.page-title {
    &__container {
        text-align: center;
        padding-top: 35px;
        padding-bottom: 70px;
        padding-left: 300px;
        padding-right: 300px;

        @media screen and (max-width: 1300px) {
            padding-left: 200px;
            padding-right: 200px;
        }

        @media screen and (max-width: 1200px) {
            padding: 35px 160px 70px;
        }

        @media screen and (max-width: 1024px) {
            padding: 35px 50px 70px;
        }

        @media screen and (max-width: 768px) {
            padding-right: 110px;
            padding-left: 30px;
            padding-bottom: 35px;
            text-align: left;
        }

        @media screen and (max-width: 600px) {
            padding-left: 25px;
            padding-bottom: 30px;
        }
    }

    &__container--no-image {
        padding-left: 340px;
        padding-right: 340px;
        padding-bottom: 50px;

        @media screen and (max-width: 1400px) {
            padding-left: 300px;
            padding-right: 300px;
        }

        @media screen and (max-width: 1300px) {
            padding-left: 200px;
            padding-right: 200px;
        }

        @media screen and (max-width: 1200px) {
            padding: 35px 160px 50px;
        }

        @media screen and (max-width: 1024px) {
            padding: 35px 50px 40px;
        }

        @media screen and (max-width: 900px) {
            padding-right: 110px;
            text-align: left;
        }

        @media screen and (max-width: 768px) {
            padding-left: 30px;
            padding-bottom: 30px;
        }

        @media screen and (max-width: 600px) {
            padding-left: 25px;
        }
    }

    &__container--evenements {
        padding-bottom: 60px;
        
        @media screen and (max-width: 800px) {
            padding-bottom: 20px;
        }
    }

    &__container--no-padding-top {
        padding-top: 0;
        padding-bottom: 60px;
        
        @media screen and (max-width: 800px) {
            padding-bottom: 20px;
        }

        @media screen and (max-width: 768px) {
            padding-top: 30px;
        }
    }

    &__surtitle {
        font-size: 16px;
        font-family: $font1;
        font-weight: 700;
        line-height: 1;
        color: $color1; 
        padding-bottom: 35px;
    }

    &__subtitle {
        font-size: 16px;
        font-family: $font1;
        font-weight: 700;
        line-height: 1;
        color: $color1; 
        padding-top: 20px;
    }

    &__surtitle--search {
        font-size: 50px;
        font-family: $font1;
        font-weight: 700;
        line-height: 1;
        color: $color1;

        @media screen and (max-width: 1300px) {
            font-size: 40px;
        }

        @media screen and (max-width: 1100px) {
            font-size: 30px;
        }

        @media screen and (max-width: 600px) {
            font-size: 25px;
        }

        @media screen and (max-width: 400px) {
            font-size: 20px;
        }
    }

    &__title--event {
        font-size: 50px;
        font-family: $font1;
        font-weight: 700;
        line-height: 1.2;
        color: $color1;

        @media screen and (max-width: 600px) {
            font-size: 40px;
        }

        @media screen and (max-width: 500px) {
            font-size: 30px;
        }

        @media screen and (max-width: 400px) {
            font-size: 25px;
        }
    }

    &__title--search {
        color: $color4;
    }

    &__infos-sup {
        display: flex;
        justify-content: center;
        margin-top: 60px;

        @media screen and (max-width: 768px) {
            justify-content: flex-start;
        }

        @media screen and (max-width: 600px) {
            margin-top: 30px;
        }

        @media screen and (max-width: 500px) {
            flex-direction: column;
        }
    }

    &__infos-sup > :last-child p {
        margin-right: 0;
    }

    &__infos {
        display: flex;
        // align-items: center;

        @media screen and (max-width: 500px) {
            margin-bottom: 25px;
        }
    }

    &__date, &__hours, &__place {
        font-size: 16px;
        font-family: $font1;
        line-height: 1;
        color: $color1;
        text-align: left; 
        margin-right: 35px;
        transform: translate(0px, 4px);
        max-width: 400px;

        @media screen and (max-width: 600px) {
            font-size: 13px;
            margin-right: 25px;
        }
    }

    &__svg-date, &__svg-clock, &__svg-place {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        fill: $color1;
        margin-right: 10px;
    }

    &__svg-dollar {
        width: 19px;
        height: 19px;
        min-width: 19px;
        min-height: 19px;
        stroke: $color1;
        stroke-width: 6px;
        margin-right: 10px;
    }
}
/*
|--------------------------------------------------------------------------
| RUBRIQUE OUVERTE
|--------------------------------------------------------------------------
*/
.rubric.js-accordion-opened {
    background: #fff;

    .rubric__transition {
        opacity: 1;
        transform: none;
        transition: opacity 700ms $easeInOutQuart 100ms, transform 700ms $easeInOutQuart 100ms;
    }

    .rubric__header--plus {
        @media screen and (min-width: 600px) {
            padding-bottom: 40px;
        }
    }

    .rubric__icon {
        transform: rotate(180deg);
        &::after { top: 0%; }
        &::before { border: solid $color4 1px; }
    }

    .rubric__icon-triangle {
        border-top: 12px solid #fff;
    }

    .rubric__title {
        color: $color4;
    }

    .rubric__surtitle {
        color: $color4;
    }
}

/*
|--------------------------------------------------------------------------
| SELECT DROPDOWN ACTIF
|--------------------------------------------------------------------------
*/
.field--select .tail-select.active {
    z-index: 102;
    // border: none;
    // box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.050);
    // transition: border 500ms $easeInOutCubic, box-shadow 500ms $easeInOutCubic;
    &::before {
        content: "";
        background: rgba($color: #000000, $alpha: 0.5);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        opacity: 1;
    }
    
    .label-inner:before { transform: translateY(-50%) rotate(-180deg); }
    .label-inner {
        color: rgba($color1, $alpha: .5);
    }
    .select-dropdown {
        opacity: 1;
        top: 100%;
        visibility: visible;
        pointer-events: all;
        transition: top 500ms $easeInOutCubic, opacity 500ms $easeInOutCubic;
    }
}

.field--filter .tail-select.active { 
    z-index: 102;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.050);

    .select-label {
        transition: box-shadow 300ms $easeInOutCubic;
        border-bottom: 1px solid rgba($color1, $alpha: 0);
    }
    .label-inner:before { transform: translateY(-50%) rotate(-180deg); }
    .label-inner {
        color: rgba($color1, $alpha: .5);
    }
    .select-dropdown {
        opacity: 1;
        top: 100%;
        visibility: visible;
        pointer-events: all;
        transition: top 500ms $easeInOutCubic, opacity 500ms $easeInOutCubic;
    }
}

.field--calendar .tail-select.active {
    .label-inner {
        background: #fff;

        &::before {
            border-top: 12px solid $color3;
        }
    }
}

/*
|--------------------------------------------------------------------------
| BUTTON ACTIVE
|--------------------------------------------------------------------------
*/
.pagination {
    &__number.js-pagination-active { 
        background: $color4;
        color: #fff;
    }
}
/*** ----- TECHNICAL ----- ***/
$browser-context: 16; // La taille initiale de la typo
$colnb: 12; // Nombre de colonnes pour la grille
$gaps: 1%;  // Padding de chaque côté d'une colonne

/*** ----- COLORS ----- ***/
$color1: #284d45;
$color2: #323232;
$color3: #fbc11f;
$color4: #8ebb52;
$color5: #8b9890;
$color6: #eff2f6;
$color7: #203e37;
$color8: #53716a;
$color9: #839189;
$color10: #d4dbda;
$color11: #81918d;
$color12: #EFF1F5;
$color13: #7EA24E;
$color14: #A5C975;

/*** ----- FONTS ----- ***/
$font1: 'CircularSTD', sans-serif;

/*** ----- EASINGS ----- ***/
$easeOutCubic: cubic-bezier(0.33, 1, 0.68, 1);
$easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
$easeInOutQuart: cubic-bezier(0.65, 0, 0.35, 1);
$easeInOutCubic: cubic-bezier(0.76, 0, 0.24, 1);
$easeInOutQuint: cubic-bezier(0.83, 0, 0.17, 1);
.overlay-search {
    position: absolute;
    width: 0%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 60;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0);
        transition: background 800ms $easeInOutQuart;
        z-index: -1;
    }
    
    &__container {
        position: absolute;
        width: 100%;
        height: 0px;
        top: 0;
        left: 0;
        background: #fff;
        overflow: hidden;
    }
    
    &__form {
        opacity: 0;
        transform: translateY(100px);
        transition: transform 800ms $easeInOutQuart, opacity 800ms $easeInOutQuart;
        @extend .y-padding-90;
        @extend .x-padding-160;
    }

    .field { margin-bottom: 20px; }
}
.field {
    position: relative;
    margin-bottom: 60px;

    @media screen and (max-width: 1024px) { margin-bottom: 50px; }
    @media screen and (max-width: 768px) { margin-bottom: 40px; }

    // Erreur
    &__error {
        margin-top: 5px;
        font: rem(13)/1 $font1;
        color: $color3;
    }

    // Input textarea et newsletter
    &--input &__input, &--textarea &__input, &--newsletter &__input {
        width: 100%;
        height: 63px;
        font: rem(18)/1.3 $font1;
        color: $color1;
        background: transparent;
        border: 1px solid $color10;
        padding-left: 30px;
    }

    &--textarea &__input {
        height: 200px;
    }

    &--input &__label, &--textarea &__label, &--newsletter &__label {
        position: absolute;
        top: 20px;
        left: 30px;
        pointer-events: none;
        font: rem(18)/1.3 $font1;
        color: $color1;
        transition: font-size 300ms, top 300ms $easeOutCubic;
    }

    &--input &__input:focus ~ &__label, &--input[data-inputvalue]:not([data-inputvalue=""]) &__label,
    &--textarea &__input:focus ~ &__label, &--textarea[data-textareavalue]:not([data-textareavalue=""]) &__label, 
    &--newsletter &__input:focus ~ &__label, &--newsletter[data-inputvalue]:not([data-inputvalue=""]) &__label {
        font-size: rem(12);
        top: -20px;
    }

    &__label--desktop { @media screen and (max-width: 440px) { display: none; } }
    &__label--mobile { display: none; @media screen and (max-width: 440px) { display: block; } }
    

    // Textarea
    &--textarea &__input {
        box-shadow: none;
        outline: none;
        resize: none;
        overflow: hidden;
        padding-top: 20px;
    }


    // Checkbox et radio
    &--checkbox &__label, &--radio &__label {
        display: inline-block;
		position: relative;
		padding-left: 35px;
        margin-bottom: 20px;
		cursor: pointer;
    }

    &__checkbox-box {
        width: 100%;
    }

    &--checkbox-inline &__label, &--radio-inline &__label {
        display: inline-block;
        margin: 0 30px 20px 0;
    }

    &--radio-inline &__label-no-margin-bottom {
        margin-bottom: 0;
    }

    &--checkbox &__text, &--radio &__text {
        font: rem(18)/1.3 $font1;
        color: $color1;
        user-select: none;
    }

    &--input-radio { margin-bottom: 0; }

    &--checkbox &__input, &--radio &__input { position: absolute; opacity: 0; cursor: pointer; }
    &--checkbox &__input:checked ~ &__box, &--radio &__input:checked ~ &__box { background: $color3; }
    &--checkbox &__input:checked ~ &__box:before, &--radio &__input:checked ~ &__box:before { transform: translate(-50%, -50%) scale(1); }
    &--checkbox &__input:checked ~ &__box, &--radio &__input:checked ~ &__box { border: none; }

    &--checkbox &__box, &--radio &__box {
        position: absolute;
        width: 22px;
        height: 22px;
        top: 2px;
        left: 0;
        background: #fff;
        border: 1px solid rgba($color1, $alpha: 0.3);
        transition: background 225ms;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 225ms $easeOutCubic;
        }
    }

    // Checkbox
    &--checkbox &__box {
        &:before {
            width: 12px;
            height: 12px;
            background: url('../medias/images/icons/hook.svg') no-repeat center / contain;
        }
    }


    // Radio
    &--radio &__box {
        border-radius: 50%;

        &:before {
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 50%;
        }
    }

    &--radio-inline {
        margin-bottom: 0;
    }


    // File
    &--file { @media screen and (max-width: 1200px) { margin-bottom: 30px; } }

    &--file &__input { width: 0.1px; height: 0.1px; opacity: 0; overflow: hidden; position: absolute; z-index: -1; }

    &--file &__label {
        display: block;
        position: relative;
        width: 100%;
        padding: 30px 110px 30px 30px;
        background: $color6;
        color: $color1;
        cursor: pointer;
        box-shadow: inset 0px 0px 0 $color6;
        transition: color 300ms, background 300ms, box-shadow 300ms $easeInOutCubic;

        &:hover {
            color: $color6;
            box-shadow: inset 0px -89px 0 $color1;

            .field__circle {
                background: #fff;
            }
        }

        @media screen and (max-width: 600px) {
            padding: 20px 110px 20px 30px;
        }
    }

    &--file &__text {
        font: rem(22)/1.3 $font1;

        @media screen and (max-width: 600px) {
            font: rem(18)/1.3 $font1;
        }
    }

    &--file &__circle {
        width: 50px;
        height: 50px;
        border: 1px solid $color1;
        border-radius: 50%;
        background: $color6;
        transition: background 300ms $easeInOutCubic;
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);

        @media screen and (max-width: 600px) {
            width: 30px;
            height: 30px;
        }
    }

    &--file &__svg {
        position: absolute;
        width: 15px;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        fill: $color1;
        transition: fill 300ms, opacity 300ms;

        @media screen and (max-width: 600px) {
            width: 12px;
        }
    }

    &--file &__x {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 2px;
        right: 5px;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        pointer-events: none;
        transition: opacity 300ms, visibility 0ms ease 300ms;

        &:hover {
            &:before, &:after { background: $color6; }
        }
        
        &:before, &:after {
            content: '';
            position: absolute;
            width: 16px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: $color1;
            transition: background 300ms;

            @media screen and (max-width: 600px) { width: 20px; }
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
    }

    &--file[data-file]:not([data-file=""]) &__label { pointer-events: none; }
    &--file[data-file]:not([data-file=""]) &__circle   { opacity: 0; }
    &--file[data-file]:not([data-file=""]) &__x     { opacity: 1; visibility: visible; pointer-events: all; }


    // Select
    &--select &__label {
        display: block;
        font: rem(18)/1.3 $font1;
        color: $color1;
        padding-bottom: 10px;
    }

    &--calendar {
        margin-bottom: 0;
        padding: 28px 80px 58px;
        background: $color7;

        @media screen and (max-width: 1024px) {
            padding-left: 50px;
            padding-right: 50px;
        }

        @media screen and (max-width: 768px) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @media screen and (max-height: 768px) {
            padding: 25px 80px 40px;
        }

        @media screen and (max-width: 600px) {
            padding-left: 25px;
            padding-right: 25px;
        }

        @media screen and (max-height: 550px) {
            padding: 15px 80px 30px;
        }
    }

    &--select .tail-select {
        position: relative;
        z-index: 1;
        border: 1px solid $color10;

        &::before {
            content: "";
            opacity: 0;
            transition: opacity 500ms $easeInOutCubic;
        }
    }

    &--filter .tail-select {
        border: none;
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.050);
    }

    &--calendar .tail-select {
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.050);
        border: none;
        border-bottom: solid #fff 1px;
        background: $color7;
    }

    &--select .select-label {
        padding: 20px 30px;
        transition: background 500ms $easeInOutCubic, border-color 500ms $easeInOutCubic;
        cursor: pointer;
        position: relative;
        background-color: #fff;
    }

    &--filter .select-label {
        border-bottom: 1px solid $color1;
        transition: box-shadow 300ms $easeInOutCubic, border-bottom 300ms $easeInOutCubic;
    }

    &--calendar .select-label {
        transition: box-shadow 300ms $easeInOutCubic;
        padding: 0;
    }

    &--select .label-inner {
        display: block;
        position: relative;
        font: rem(18)/1.3 $font1;
        color: $color1;
        transition: color 500ms $easeInOutCubic;

        &::before {
            content: "";
            width: 0; 
            height: 0; 
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 12px solid $color3;
            transition: border 400ms $easeInOutCubic;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            transition: transform 500ms $easeInOutCubic;
        }
    }

    &--calendar .label-inner {
        padding: 20px 30px;
        background: $color7;
        transition: background 500ms $easeInOutCubic;
        color: #fff;

        &::before {
            border-top: 12px solid #fff;
            transition: border-top 500ms $easeInOutCubic;
            right: 20px;
        }

        @media screen and (max-height: 500px) {
            padding: 30px 20px 10px;
        }
    }

    &--select .select-dropdown {
        position: absolute;
        width: 100%;
        top: 0%;
        left: 0;
        opacity: 0;
        background: #fff;
        border-top-color: rgba($color1, 0.025);
        box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.050);
        z-index: 0;
        visibility: hidden;
        pointer-events: none;
        transition: top 500ms $easeInOutCubic, opacity 500ms $easeInOutCubic, visibility 0ms ease 500ms;
    }

    &--select .dropdown-inner {
        overflow: auto;
        max-height: 195px;

        scrollbar-width: none;

        &::-webkit-scrollbar { display: none; }
    }

    &--select .dropdown-option {
        font: rem(18)/1.3 $font1;
        color: $color1;
        padding: 20px 30px;
        background: #fff;
        cursor: pointer;
        transition: background 200ms $easeInOutCubic, color 200ms $easeInOutCubic;

        &:hover { 
            background: $color4; color: #fff; 
            &.selected { color: #fff; }
        }

        &.selected { 
            color: $color4;
        }

    }

    // Select natif
    &--select select {
        width: 100%; 
        padding: 20px 30px;
        font: rem(18)/1.3 $font1;
        color: #fff;
        border: none;
        border-bottom: 1px solid #fff;
        background: transparent;
        outline: none;
        -webkit-appearance: none;
        background: url('../medias/images/icons/triangle_arrow.svg') no-repeat calc(100% - 20px) 50% / 12px 12px;
    }

    &--formulaire select {
        border: 1px solid $color10;
        color: $color1;
    }

    &--filter select {
        color: $color1;
        border: none;
        border-bottom: solid $color1 1px;
    }

    &--select option {
        color: $color1;
    }

    select::-ms-expand { display: none; }


    // Recherche
    &--search {
        text-align: right;
    }

    &--search &__input {
        width: 100%;
        height: 90px;
        padding-right: 100px;
        font: rem(40)/1.3 $font1;
        color: $color7;
        text-align: left;
        background: transparent;
        border: none;
        border-bottom: 1px solid rgba($color7, 0.2);
        transition: border 300ms;

        @media screen and (max-width: 1100px) { height: 70px; font-size: rem(30); }
        @media screen and (max-width: 768px)  { height: 50px; padding-right: 70px; font-size: rem(23); }
        @media screen and (max-width: 600px)  { font-size: rem(21); }
        @media screen and (max-width: 500px)  { padding-right: 50px; font-size: rem(17); }
        @media screen and (max-width: 425px)  { font-size: rem(15); }
        @media screen and (max-width: 320px)  { font-size: rem(14); }
    }

    &--search[data-inputvalue]:not([data-inputvalue=""]) &__x {
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms;
    }
    
    &--search {
        @media screen and (max-width: 600px) { margin-bottom: 0; }
        ::-webkit-input-placeholder { color: $color7; font-weight: normal; }
        ::-moz-placeholder { color: $color7; font-weight: normal; }
        :-ms-input-placeholder { color: $color7; font-weight: normal; }
        :-moz-placeholder { color: $color7; font-weight: normal; }
    }
    
    &--search &__x {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 50%;
        right: 65px;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        transition: opacity 300ms, visibility 0ms ease 300ms;

        @media screen and (max-width: 768px) { right: 35px; }
        @media screen and (max-width: 600px) { width: 20px; height: 20px; }
        @media screen and (max-width: 500px) { right: 25px; }
        
        &:before, &:after {
            content: '';
            position: absolute;
            width: 25px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: $color7;
            
            @media screen and (max-width: 600px) { width: 20px; }
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
    }
    
    &--search &__btn {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        right: 0;
        stroke: #fff;
        transform: translateY(-50%);

        @media screen and (max-width: 1100px) { width: 24px; height: 24px; }
        @media screen and (max-width: 500px)  { width: 20px; height: 20px; }
    }

    &--search &__svg {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 0;
        fill: none;
        stroke-width: 2px;
        stroke: $color7;
        transform: translateY(-50%);

        @media screen and (max-width: 1100px) { width: 24px; height: 24px; }
        @media screen and (max-width: 500px) { width: 20px; height: 20px; }
    }
    

    // Newsletter
    &--newsletter { margin-bottom: 0; }
    &--newsletter &__input { height: 47px; padding-right: 60px; }
    &--newsletter &__label { top: 7px; }
    &--newsletter &__input:focus ~ &__label, &--newsletter[data-inputvalue]:not([data-inputvalue=""]) &__label { top: -10px; }

    &--newsletter &__btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $color5;
            border-radius: 50%;
            transition: transform 300ms $easeOutCubic;
            z-index: -1;
        }

        &:hover:before { transform: scale(1.1); }
    }

    &--newsletter &__svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        stroke: #fff;
        transform: translate(-50%, -50%) rotate(-90deg);
    }
}

.field--result {
    
    @media screen and (max-width: 768px) {
        margin-bottom: 0;
    }
}
@font-face {
    font-family: 'CircularSTD';
    src: url('../fonts/CircularStd-Book.otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'CircularSTD';
    src: url('../fonts/CircularStd-BookItalic.otf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'CircularSTD';
    src: url('../fonts/CircularStd-Medium.otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'CircularSTD';
    src: url('../fonts/CircularStd-MediumItalic.otf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'CircularSTD';
    src: url('../fonts/CircularStd-Bold.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'CircularSTD';
    src: url('../fonts/CircularStd-BoldItalic.otf');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
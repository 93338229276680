// Les actualités en vedette
.featured-news {
    --featured-news-item-gaps: 50px;
    --featured-news-list-column-number: 3;

    @media screen and (max-width: 1400px) { --featured-news-item-gaps: 30px; }
    @media screen and (max-width: 1200px) { --featured-news-item-gaps: 15px; }
    @media screen and (max-width: 1024px) { --featured-news-item-gaps: 10px; }
    @media screen and (max-width: 768px)  { --featured-news-list-column-number: 2; }
    @media screen and (max-width: 460px)  { --featured-news-list-column-number: 1; --featured-news-item-gaps: 1%; }
}

.featured-news {
    &__container {
        @extend .x-padding-120;
        @extend .top-padding-80;
        @extend .bottom-padding-150; 
    }

    &__container--news-detail {
        padding-top: 0;
        @extend .bottom-padding-120
    }

    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        @extend .bottom-margin-90;
    }

    &__title-container {
        display: flex;
        align-items: center;
    }

    &__icon-container {
        position: relative;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 1px solid $color7;
    }
    
    &__svg {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 50%;
        fill: $color7;
        transform: translate(-50%, -50%);
    }
    
    &__title {
        flex: 1;
        margin-left: 25px;
        @extend .large-title;

        @media screen and (max-width: 1024px) { margin-left: 20px; }
        @media screen and (max-width: 768px)  { margin-left: 15px; }
    }

    &__button {
        @media screen and (max-width: 768px) { display: none; }
    }
    
    &__list {
        display: flex;
        flex-flow: row wrap;
        width: calc(100% + (var(--featured-news-item-gaps) * 2));
        margin: 0 calc(0px - var(--featured-news-item-gaps));
    }
    
    &__item {
        width: calc(100% / var(--featured-news-list-column-number));
        padding-right: var(--featured-news-item-gaps);
        padding-left: var(--featured-news-item-gaps);
    }
    
    &__item:nth-child(3n+2) {
        @media screen and (min-width: 461px) {
            transition-delay: 100ms;

            .news-block {
                &__picture-transition:after {
                    transition-delay: 100ms;
                }
            }
        }
    }

    &__item:nth-child(3n+3) {
        @media screen and (min-width: 461px) {
            transition-delay: 200ms;

            .news-block {
                &__picture-transition:after {
                    transition-delay: 200ms;
                }
            }
        }
    }

    &__item:nth-child(n+2) {
        @media screen and (max-width: 460px) { margin-top: 40px; }
    }

    &__item:last-child {
        @media screen and (max-width: 768px) and (min-width: 461px) { display: none; }
    }

    &__item-padding:nth-child(n+4) {
        padding-top: 100px;

        @media screen and (max-width: 768px) {
            padding-top: 0;
        }
    }

    &__item-padding:nth-child(n+3) {

        @media screen and (max-width: 768px) {
            padding-top: 80px;
        }

        @media screen and (max-width: 460px) {
            padding-top: 0;
        }
    }

    &__item-padding:nth-child(n+2) {

        @media screen and (max-width: 460px) {
            padding-top: 10px;
        }
    }
    
    &__button-mobile {
        margin-top: 40px;
        
        @media screen and (min-width: 769px) { display: none; }
        @media screen and (max-width: 460px) { margin-top: 30px; }
    }
}


// Bloc d'actualité
.news-block {
    --news-block-infos-space: 20px;
    
    @media screen and (max-width: 460px)  { --news-block-infos-space: 15px; }
}
.news-block {
    width: 100%;
    border-top: 1px solid $color7;
    padding-top: var(--news-block-infos-space);

    &__infos {
        display: block;
        margin-bottom: var(--news-block-infos-space);
        font: rem(16)/1.3 $font1;
        color: $color7;

        @media screen and (max-width: 600px) { font-size: rem(14); }
    }

    &__strong {
        font-weight: 700;
    }

    &__picture-transition {
        position: relative;
    }
    
    &__picture-transition:after {
        content: '';
        position: absolute;
        top: 0%;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color4;
        z-index: 1;
        transition: top 1000ms $easeInOutQuint;
    }

    &__picture-transition[data-scrollfire="fire"]:after { top: 100%; }
    
    &__picture {
        position: relative;
        padding-top: ratio(330, 360);
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    
    &__picture:before, &__picture:after {
        position: absolute;
        opacity: 0;
        z-index: 1;
        transition: opacity 400ms;
    }

    &__picture:before {
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($color7, 0.4);
    }

    &__picture:after {
        content: 'Lire la suite';
        top: 50%;
        left: 50%;
        font: 700 rem(14)/1 $font1;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: translate(-50%, -50%);
    }

    &__title {
        margin-top: 30px;
        @extend .small-title;

        @media screen and (max-width: 1024px) { margin-top: 20px; }
    }

    &:hover &__picture:before, &:hover &__picture:after {
        opacity: 1;
    }
}
.intro {
    &__container {
        width: 100%;
        position: relative;
    }

    &__image-container {
        width: 100%;
        height: 620px;

        @media screen and (max-width: 900px) {
            height: 520px;
        }

        @media screen and (max-width: 768px) {
            height: 420px;
        }

        @media screen and (max-width: 600px) {
            height: 320px;
        }

        @media screen and (max-width: 400px) {
            height: 220px;
        }
    }

    &__credit {
        padding: 15px 30px;
        background: rgba($color: #000000, $alpha: 0.4);
        font-family: $font1;
        line-height: 1;
        font-size: 11px;
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
    }

    &__content {
        padding: 0 160px;
        margin-top: -170px;
        
        @media screen and (max-width: 768px) {
            padding: 0 30px;
        }

        @media screen and (max-width: 600px) {
            margin-top: 0;
        }
    }

    &__content--no-image {
        background: transparent;
        padding: 0 350px;
        margin: 0;

        @media screen and (max-width: 1300px) {
            padding: 0 200px;
        }

        @media screen and (max-width: 1200px) {
            padding: 0 160px;
        }

        @media screen and (max-width: 1024px) {
            padding: 0 50px;
        }

        @media screen and (max-width: 768px) {
            padding: 0 30px;
        }

        @media screen and (max-width: 600px) {
            padding: 0 25px;
        }
    }

    &__content--dynamic {
        padding: 0 160px;

        @media screen and (max-width: 1400px) {
            padding: 0 120px;
        }

        @media screen and (max-width: 1200px) {
            padding: 0 80px;
        }

        @media screen and (max-width: 1024px) {
            padding: 0 25px;
        }

        @media screen and (max-width: 768px) {
            margin-top: 0;
            padding: 0x 30px;
        }

        @media screen and (max-width: 600px) {
            margin-top: 0;
            padding: 0x 25px;
        }
    }

    &__text-content {
        width: 100%;
        padding: 90px 190px 50px 190px;
        background: #fff;
        position: relative;
        z-index: 2;
        min-height: 170px;   //pour pas qu'il y aille un espace entre le bloc de texte et le reste de la page avec le background blanc (si ya pas grand texte)

        @media screen and (max-width: 1100px) {
            padding: 75px 120px 50px 120px;
        }

        @media screen and (max-width: 900px) {
            padding: 50px 75px;
        }
        
        @media screen and (max-width: 730px) {
            min-height: inherit;
        }
        
        @media screen and (max-width: 600px) {
            padding: 50px 0 0 0;
        }
    }

    &__text-content--no-padding {
        padding: 0;
    }

    &__text-content--no-image {
        padding: 0;
    }

    &__text-content--dynamic {
        padding: 50px 80px;

        @media screen and (max-width: 1024px) {
            padding: 50px 25px;
        }

        @media screen and (max-width: 768px) {
            padding: 30px 25px;
        }
    }

    &__text {
        text-align: center;
        line-height: 1.5;
        font-weight: 700;
        @include responsiveFontSize(28, 20);
    }

    &__text--no-image {
        @media screen and (max-width: 900px) {
            text-align: left;
        }
    }

    &__text--date {
        @include responsiveFontSize(35, 20);
        
        @media screen and (max-width: 768px) {
            text-align: left;
        }
    }
}
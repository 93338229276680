.overlay-share {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 15;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0);
        transition: background 800ms;
        z-index: -1;
    }

	&__container {
        position: absolute;
        padding: 85px;
        width: 520px;
        top: 50%;
        left: 50%;
		background: #fff;
        text-align: center;
        opacity: 0;
        z-index: 151;
        pointer-events: none;
        transform: translateX(-50%);
        transition: opacity 800ms $easeInOutQuart, transform 800ms $easeInOutQuart;

        @media screen and (max-width: 1200px) { width: 490px; }
        @media screen and (max-width: 1024px) { width: 400px; padding: 50px; }
        @media screen and (max-width: 600px)  {
            width: auto;
            right: 20px;
            left: 20px;
            padding: 50px 20px;
            transform: none;
        }
    }

    &__title {
        margin-bottom: 20px;
        @extend .medium-title;
    }

    &__close {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 20px;
        right: 20px;
        cursor: pointer;

        &:hover { &:before, &:after { background: $color5; }}

        &:before, &:after {
            content: '';
            position: absolute;
            width: 16px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: $color1;
            transition: background 300ms;
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg) ; }
        &:after { transform: translate(-50%, -50%) rotate(-45deg) ; }
    }

    &__list {
        position: relative;
        margin-top: 16px;
    }

    &__list-li {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 20px;

            @media screen and (max-width: 600px) { margin-right: 10px; }
        }
    }

    &__link {
        position: relative;
        width: 45px;
        height: 45px;
        background: $color1;
        border-radius: 50%;
        transition: background 300ms;

        &:hover { background: $color5; }
    }

    &__svg {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50%;
        left: 50%;
        fill: #fff;
        transform: translate(-50%, -50%);
    }
}

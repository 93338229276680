.page-container .c-interactiveMap {
    --green: rgb(142, 187, 82);
    --hover-green: rgb(109, 150, 74);
    --dark-green: rgb(32, 62, 55);

    // Font sizes
    --fz-35-map: 2.1875rem;
    --fz-22-map: 1.375rem;
    --fz-20-map: 1.25rem;
    --fz-17-map: 1.0625rem;
    --fz-15-map: 0.9375rem;

    .c-map-quit-button {
        --background-color: var(--green);
        --background-color-hover: var(--hover-green);
    }

    .c-map-ui-button {
        --icon-color: var(--dark-green);
    }

    .c-map-pin {
        --bg-color: var(--green);
        --bg-color-hover: var(--hover-green);
        --bg-color-active: var(--dark-green);
    }

    .c-map-close-button::after {
        background-color: var(--dark-green);
    }

    .c-map-reset-button {
        --button-color: var(--dark-green);
    }

    .c-map-layers-popup {
        --active-color: var(--dark-green);
    }

    .c-map-popup {
        color: var(--dark-green);
    }

    .c-map-categories,
    .c-map-subcategories,
    .c-map-locations__list,
    .c-map-location-detail,
    .c-map-location-button,
    .c-map-related,
    .c-map-title,
    .c-map-subtitle,
    .c-map-share-button{
        --text-color: var(--dark-green);
        --color: var(--dark-green);
    }

    .c-map-back-button {
        --color-hover: var(--dark-green);
    }

    .c-map-button:not(.c-map-button--secondary) {
        --button-background-color: var(--dark-green);
    }

    .c-interactiveMap__logo {
        margin: 15px 0;
    }

    .c-map-mobile-header__logo {
        width: 93px;
        height: 50px;
    }

    .c-interactiveMap__mobile-title {
        color: var(--dark-green);
    }

    .dynamic {
        p,
        ul li,
        ol li {
            font-size: 0.85rem;
        }
        ul li,
        ol li {
            padding-bottom: 5px;
        }
        ul li::before {
            background-color: var(--dark-green);
        }
        ol li:before {
            font-size: 0.85rem;
        }
    }
}

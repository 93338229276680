.overlay-calendar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
	visibility: hidden;
    pointer-events: none;
    z-index: 49;

    &::-webkit-scrollbar { display: none; }

	&__container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        z-index: 5;
        pointer-events: none;
        transition: opacity 800ms $easeOutQuart;

        @media screen and (max-height: 500px) {
            overflow-y: auto;
        }
    }

    &__content {
        height: 100%;
        opacity: 0;
        transform: translateY(100px);
        transition: transform 800ms $easeOutQuart, opacity 800ms $easeOutQuart;

        @media screen and (max-width: 768px) {
            width: 100%;
            margin: 0 auto;
        }
    }

    &__calendar-container {
        width: 100%;
        height: calc(100% - 150px);
        background: $color1;
        position: relative;

        @media screen and (max-height: 768px) {
            height: calc(100% - 129px);
        }

        @media screen and (max-height: 550px) {
            height: calc(100% - 109px);
        }

        @media screen and (max-height: 500px) {
            min-height: 400px;
        }
    }

    &__calendar-content {
        height: 100%;

        @media screen and (max-width: 768px) { max-width: 400px; margin: 0 auto; }
        @media screen and (max-width: 400px) { max-width: 320px; margin: 0 auto; }
    }
    
    &__calendar {
        padding: 0 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;

        @media screen and (max-width: 768px) {
            padding: 0 25px;
        }

        @media screen and (max-height: 768px) {
            justify-content: flex-end;
            padding-top: 25px;
        }
    }

    &__btn {
        display: flex;
        align-items: center; 
        position: absolute;
        left: 50%;
        bottom: 50px;
        transform: translateX(-50%); 

        @media screen and (max-width: 1200px) {
            bottom: 45px;
        }

        @media screen and (max-width: 1024px) {
            bottom: 40px;
        }

        @media screen and (max-width: 768px) {
            bottom: 35px;
        }

        @media screen and (max-height: 500px) {
            bottom: 25px;
        }
    }
    
    &__btn:hover &__svg-refresh {
        transform: rotate(360deg);
    }

    &__text {
        color: #fff;
        font-family: $font1;
        letter-spacing: 1.2px;
        font-weight: 700;
        line-height: 1;
        font-size: 14px;
        text-transform: uppercase;

        @media screen and (max-width: 400px) {
            text-align: center;
        }
    }

    &__svg-refresh {
        width: 25px;
        height: 25px;
        fill: none;
        stroke: #fff;
        stroke-width: 3px;
        margin-left: 15px;
        transform: rotate(-360deg);
        transition: transform 1000ms $easeInOutCubic;
    }
}

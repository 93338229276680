.breadcrumb {

    &__container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 100px; 
        padding-bottom: 60px;

        @media screen and (max-width: 768px) {
            justify-content: flex-start;
            padding-bottom: 0;
            padding-top: 30px;
        }
    }

    &__text {
        padding: 0 25px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
    }

    &__container--contact {
        padding: 50px 0 50px 80px;
        justify-content: flex-start;
        flex-wrap: wrap;

        @media screen and (max-width: 1100px) {
            padding: 50px 0 50px 60px;
        }

        @media screen and (max-width: 768px) { display: none; }
    }

    &__container--event {
        padding-bottom: 30px;
    }

    &__container--mobile { 
        display: none; 
        
        @media screen and (max-width: 768px) { 
            display: flex; 
            padding: 15px 0 25px 0;
        } 
    }

    &__link-home {
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
        margin-bottom: 5px;
        position: relative;
    }

    &__svg-home {
        width: 100%;
        height: 100%;
        fill: none;
        stroke: $color1;
        stroke-width: 3px;

        &:hover { stroke: $color4; }
        transition: stroke 300ms $easeInOutCubic;
    }

    &__item {
        min-width: 0;
        padding: 0 20px;
        font-family: $font1;
        line-height: 1;
        color: $color1; 
        font-size: 14px;
        position: relative;

        &:before {
            content: "/";
            position: absolute;
            top: 0;
            left: -5px;
            color: $color1;
        }
    }

    &__actual-page {
        color: $color4; 
    }

    &__link-share {
        width: 15px;
        height: 18px;
        min-width: 15px;
        min-height: 18px;
        font-family: "CircularSTD", sans-serif;
        line-height: 1;
        font-size: 14px;
        position: relative;

        &:before {
            content: "/";
            position: absolute;
            top: 3px;
            left: -25px;
            color: $color1;
        }

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    &__svg-share {
        width: 100%;
        height: 100%;
        fill: $color1;
        stroke: $color1;

        &:hover { stroke: $color4; fill: $color4; }
        transition: stroke 300ms $easeInOutCubic, fill 300ms $easeInOutCubic;
    }
}
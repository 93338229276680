.page-container {
    position: relative;
}

.sticky-buttons {
    position: absolute;
    width: var(--sticky-buttons-dimensions);
    top: 0;
    right: 80px;
    bottom: 0;
    transform: translateX(50%);
    z-index: 48;
    pointer-events: none;

    @media screen and (max-width: 1024px) { right: 50px; }
    @media screen and (max-width: 768px)  { right: 40px; }

    &__container {
        position: sticky;
        top: var(--sticky-buttons-spaces);
        margin-top: var(--sticky-buttons-spaces);
        margin-bottom: 50px;
        transition: margin 300ms $easeOutCubic; 
    }
}

.show-alerts {
    .sticky-buttons {
        &__container {
            margin-top: calc(var(--sticky-buttons-spaces) + var(--alert-height));
        }
    }
}

.application {
    @include paragraph(); 
}

.errors {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;

    &__container {
        background: rgba($color6, $alpha: 0.5);
        padding: 50px 100px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 50px;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 50px 25px;
        }
    }

    &__home {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }

    &__link {
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
        margin-right: 15px;
        transform: translateY(-2px);
    }

    &__svg {
        width: 100%;
        height: 100%;
        fill: none;
        stroke: $color1;
        stroke-width: 3px;

        &:hover { stroke: $color4; }
        transition: stroke 300ms $easeInOutCubic;
    }

    &__title {
        @include large-title();
        text-align: center;
        padding-bottom: 50px;
    }

    &__text {
        @include paragraph();
    }

    &__btn-home {
        margin-top: 50px;
    }
}
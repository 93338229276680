.overlay-menu {
    position: fixed;
    top: var(--header-height);
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 10;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0);
        transition: background 800ms;
        z-index: -1;
    }

    &__container {
        position: absolute;
        width: 500px;
        padding: 60px 0;
        top: 0;
        left: -500px;
        bottom: 0;
        background: #fff;
        overflow-y: auto;
		overflow-x: hidden;
        scrollbar-width: none;
        transition: left 800ms $easeInOutQuart;

        @media screen and (max-width: 600px) { width: 100%; left: -100%; padding: 40px 0; }
    }

    &__container::-webkit-scrollbar {
        display: none;
    }

    &__main-level {
        position: relative;
        margin-bottom: 40px;
        opacity: 0;
        transform: translateX(-200px);
        transition: opacity 1000ms $easeInOutQuart, transform 1000ms $easeInOutQuart; 

        @media screen and (max-width: 600px) { margin-bottom: 35px; }
    }

    &__main-level-li { }
    
    &__main-level:after {
        content: '';
        position: absolute;
        right: 30px;
        height: 1px;
        bottom: 0;
        left: 30px;
        background: rgba($color7, 0.2);

        @media screen and (max-width: 600px) { right: 20px; left: 20px; }
    }
    
    &__main-level-title {
        display: block;
        font: rem(35)/1 $font1;
        color: $color1;
        margin: 0 30px 20px;

        @media screen and (max-width: 600px) { font-size: rem(30); margin: 0 20px 10px; }
    }

    &__second-level { 
        > :last-child {
            padding-bottom: 40px;
            transition: padding-bottom 700ms $easeInOutQuart;

            @media screen and (max-width: 600px) { padding-bottom: 35px; }
        }
    }
    
    &__second-level-li { }

    &__second-level-button {
        position: relative;
        margin: 0 30px;
        padding: 15px 20px 0 0;
        font: 700 rem(25)/1.3 $font1;
        color: $color7;
        transition: padding 700ms $easeInOutQuart;

        @media screen and (max-width: 600px) { margin: 0 20px; font-size: rem(22); }
    }
    
    &__second-level-button:hover:after {
        transform: rotate(180deg);
    }
    
    &__second-level-button:after {
        content: '';
        position: absolute;
        width: 0; 
        height: 0; 
        top: 30px;
        right: 0; 
        border-top: 8px solid $color3;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        z-index: 10;
        transition: transform 400ms $easeOutCubic;

        @media screen and (max-width: 600px) { top: 27px; }
    }
    
    &__third-level {
        height: 0;
        overflow: hidden;
        background: $color12;
        opacity: 0;
        transition: opacity 700ms $easeInOutQuart;

        > :last-child {  
            margin-bottom: 0;
        } 
    }

    &__third-level-li {}

    &__third-level-li:first-child &__third-level-link {
        margin-top: 20px;
    }

    &__third-level-li:last-child &__third-level-link {
        margin-bottom: 20px;
    }
    
    &__third-level-link {
        margin: 10px 30px 0;
        font: rem(17)/1.5 $font1;
        color: $color7;

        @media screen and (max-width: 600px) { font-size: rem(16); margin: 10px 20px 0; }
        @media screen and (max-width: 425px) { font-size: rem(15); }
    }

    &__other-links {
        padding-left: 30px;
        margin-bottom: 20px;

        @media screen and (max-width: 600px) {
            padding-left: 25px;
        }
    }

    &__link-list {
        margin-top: 12.5px;
    }

    &__link-list:first-child {
        margin-top: 0;
    }

    &__link {
        @include paragraph()
    }


    &__medias {
        padding-left: 30px;
        
        @media screen and (max-width: 600px) {
            padding-left: 25px;
        }
    }

    &__facebook {
        width: 12px;
        height: 18px;
    }

    &__svg-facebook {
        width: 100%;
        height: 100%;
        fill: $color1;
    }
}
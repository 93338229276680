/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-dropdown {
    .overlay-dropdown {
        visibility: visible;
        pointer-events: all;
        
        &__container:after { background: rgba($color1, 0.2); }

        &__background { background: rgba(#000, 0.4); }

        &__section { transition: opacity 800ms $easeInOutQuart, visibility 0ms ease 800ms; }

        &__sub-section-container { transition: transform 800ms $easeInOutQuart; }
    }
}


/*
|--------------------------------------------------------------------------
| SECTION VISIBLE
|--------------------------------------------------------------------------
*/
@mixin section($section) {
    .show-overlay-dropdown[data-section="#{$section}"] {
        .header {
            [data-section="#{$section}"] {
                &:after { transform: translateY(-50%) rotate(-180deg); }
                &:before { height: 4px; }
            }
        }
        
        .overlay-dropdown [data-section="#{$section}"] {
            opacity: 1;
            visibility: visible;
            transition: opacity 800ms $easeInOutQuart;

            .overlay-dropdown__sub-section-container {
                transform: none;
                transition: transform 800ms $easeInOutQuart;
            }
        }
    }
}

@include section(mrc);
@include section(services);
@include section(decouvrir);
@include section(developpement);



/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-dropdown.closing-overlay-dropdown {
    .overlay-dropdown {
        
        &__container:after { background: rgba($color1, 0); }

        &__background { background: rgba(#000, 0); }
    }
}
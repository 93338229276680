.contact-banner {
    &__container {
        padding-right: 140px;

        @media screen and (max-width: 1200px) {
            padding-right: 100px;
        }

        @media screen and (max-width: 1024px) {
            padding-right: 50px;
        }

        @media screen and (max-width: 768px) {
            padding-right: 30px;
            padding-top: 25px;
        }

        @media screen and (max-width: 600px) {
            padding-right: 25px;
        }
    }

    &__content {
        display: flex;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__picture-container {
        position: relative;
        width: 48%;
        height: auto;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__img-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    &__img {
        transition: transform 500ms $easeInOutQuart;
    }

    &__link-container {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__button {
        max-height: 90px;
        background: $color4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        align-self: flex-end;
    }

    &__button-text {
        font-family: $font1;
        line-height: 1.2;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        padding: 0 40px;

        @media screen and (max-width: 900px) {
            padding: 0 20px;
        }

        @media screen and (max-width: 768px) {
            padding: 0 40px;
        }

        @media screen and (max-width: 500px) {
            padding: 0 20px;
            font-size: 15px;
        }
    }

    &__svg-container {
        width: 90px;
        height: 90px;
        background: $color13;
        transition: background 300ms;
        position: relative;

        @media screen and (max-width: 900px) {
            width: 60px;
            height: 60px;
        }
        
        @media screen and (max-width: 768px) {
            width: 90px;
            height: 90px;
        }
        
        @media screen and (max-width: 500px) {
            width: 60px;
            height: 60px;
        }
    }

    &__button:hover &__svg-container {
        background: $color14;
    }

    &__svg {
        width: 18px;
        height: 32px;
        fill: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: transform $easeInOutCubic;

        animation-name: bounce;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }

    @keyframes bounce {
        0%  { transform: translate(-50%,-50%); }
        50% { transform: translate(-50%,-25%); }
        100% { transform: translate(-50%,-50%); }
    }

    &__link:hover &__img {
        transform: scale(1.1);
    }

    &__text-container {
        width: 52%;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__text {
        width: 100%;
        padding: 0 0 80px 80px;

        @media screen and (max-width: 1100px) {
            padding: 0 0 80px 60px;
        }
        
        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-top: 25px;
            padding-bottom: 0;
        }
    }

    &__text--mobile {
        display: none;
        
        @media screen and (max-width: 768px) {
            display: block;
        }
    }

    &__title, &__phone-number {
        font-family: $font1;
        line-height: 1.3;
        color: $color1;
        font-size: 55px;
        font-weight: 700;

        @media screen and (max-width: 900px) {
            font-size: 45px;
        }
        
        @media screen and (max-width: 800px) {
            font-size: 40px;
        }
        
        @media screen and (max-width: 800px) {
            font-size: 35px;
        }
        
        @media screen and (max-width: 400px) {
            font-size: 30px;
        }
    }

    &__phone-number, &__phone-extension {
        color: $color4;
    }

    &__phone {
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    &__phone-number {
        margin-right: 15px;
    }

    &__phone-extension {
        font-family: $font1;
        font-size: 35px;

        @media screen and (max-width: 900px) {
            font-size: 25px;
        }

        @media screen and (max-width: 800px) {
            font-size: 20px;
        }
    }

    &__fax, &__phone-no-charge, &__email {
        margin-bottom: 15px;
    }

    &__address, &__fax, &__phone-no-charge, &__email, &__schedule-title, &__hours {
        font-family: $font1;
        line-height: 1.2;
        color: $color1;
        font-size: 18px;
    }

    &__email-span {
        box-shadow: inset 0px -2px 0 $color3;
        transition: box-shadow 300ms $easeInOutCubic;
    }

    &__email:hover &__email-span {
        box-shadow: inset 0px -20px 0 $color3;
    }

    &__schedule-title, &__hours {
        line-height: 1.7;
    }

    &__address {
        margin-bottom: 15px;
        line-height: 1.4;
    }

    &__schedule {
        margin-top: 20px;
    }

    &__schedule-title {
        font-weight: 700;
    }

    &__title--desktop {
        
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    &__phone--desktop {
        
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}
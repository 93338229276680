.gallery {
    display: flex;
    flex-flow: row nowrap;
    max-width: 880px;
    height: 350px;
    @extend .top-margin-50;

    @media screen and (max-width: 768px) { height: 335px; }
    @media screen and (max-width: 500px) { height: 200px; }

    &__left {
        flex: 1;
        margin-right: 30px;

        @media screen and (max-width: 768px) { margin-right: 15px; }
        @media screen and (max-width: 500px) { margin-right: 10px; }
    }

    &__right {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        width: 160px;

        @media screen and (max-width: 500px) { width: 95px; }
    }

    &__block {
        position: relative;
        height: 100%;
    }

    &__block:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #31473A;
        opacity: 0;
        transition: opacity 300ms;
        pointer-events: none;
    }

    &__link {
        display: block;
        height: 100%;
    }

    
    &__block { display: none; }
    &__block-1, &__block-2, &__block-3 { display: block; }
    &__block-1 .gallery__link, &__block-2 .gallery__link, &__block-3 .gallery__link { display: block; height: 100%; }
    &__block-2, &__block-3 {
        height: 160px;

        @media screen and (max-width: 500px) { height: 95px; }
    }
    &__block-3:before{ opacity: 0.8; }
    &__block:hover:before { opacity: 0.5; }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__icon{
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        left: 50%;
        z-index: 1;
        pointer-events: none;
        transform: translate(-50%, -50%);
    }
}

.section {
    &__container { overflow: hidden; }

    &__container--rubric-document {
        @media screen and (max-width: 768px) { padding-left: 55px; }
        @media screen and (max-width: 600px) { padding-left: 45px; }
    }

    &__container--filter { overflow: visible; }

    &__title { @extend .large-title; }

    &__subtitle {
        margin-top: 20px;
        font-weight: 700;
    }
}
:root {
    --overlay-fast-links-close-dimension: 80px;
    --overlay-fast-links-side-width: 80px;

    @media screen and (max-width: 1200px) { --overlay-fast-links-close-dimension: 70px; }
    @media screen and (max-width: 1024px) { --overlay-fast-links-close-dimension: 40px; }
}

.overlay-fast-links {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    z-index: 101;
    visibility: hidden;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0);
        transition: background 800ms $easeInOutQuart;
        z-index: -1;
    }
    
    &__container {
        position: absolute;
        width: 100%;
        height: 0px;
        top: 0;
        left: 0;
        background: #fff;
        overflow: hidden;
    }
    
    &__scroll {
        max-height: calc(var(--vh, 1vh) * 100);
        padding-top: 90px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;

        @media screen and (max-width: 1200px) { padding-top: 65px; }
    }

    &__scroll::-webkit-scrollbar {
        display: none;
    }

    &__title-container {
        position: relative;
    }

    &__title-container:before, &__title-container:after {
        content: '';
        position: absolute;
        top: 50%;
        height: 2px;
        background: rgba(#4B4B4B, 0.2);
        transform: translateY(-50%);
    }
    
    &__title-container:before {
        left: 0;
        right: 100%;
        transition: right 1000ms $easeInOutQuart 400ms;
    }
    
    &__title-container:after {
        right: 0;
        left: 100%;
        transition: left 1000ms $easeInOutQuart 400ms;
    }
    
    &__title {
        margin-bottom: 60px;
        font-family: $font1;
        font-weight: 500;
        line-height: 1;
        color: $color1;
        text-align: center;
        @include responsiveFontSize(35, 24);

        @media screen and (max-width: 1200px) { margin-bottom: 50px; }
        @media screen and (max-width: 1024px) { margin-bottom: 30px; }
    }

    &__slider-container {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding: 0 80px 40px;

        @media screen and (max-width: 1400px) { padding: 0 60px 40px; }
        @media screen and (max-width: 1024px) { padding: 0 50px 30px; }
        @media screen and (max-width: 768px)  { padding: 0 30px 30px; }
        @media screen and (max-width: 600px)  { padding: 0 25px 30px; }
        @media screen and (max-width: 500px)  { padding: 0 20px 30px; }
        @media screen and (max-width: 400px)  { padding: 0 15px 30px; }
    }

    &__slide {
        text-align: center;
    }
    
    &__fast-link {
        display: block;
        padding: 20px 0;
        max-width: 150px;
        margin: 0 auto;

        @media screen and (max-width: 320px) { max-width: 130px; }
    }

    &__circle {
        position: relative;
        width: 70px;
        height: 70px;
        margin: 0 auto 20px;

        @media screen and (max-width: 768px) { width: 60px; height: 60px; margin-bottom: 15px; }
        @media screen and (max-width: 500px) { width: 50px; height: 50px; margin-bottom: 10px; }
    }
    
    &__circle:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        border: 1px solid $color7;
        transition: transform 300ms $easeOutCubic;
    }
    
    &__svg {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 50%;
        fill: $color7;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 768px) { width: 26px; height: 26px; }
        @media screen and (max-width: 500px) { width: 24px; height: 24px; }
    }
    
    &__fast-link-title {
        font-family: $font1;
        line-height: 1.5;
        color: $color1;
        @include responsiveFontSize(18, 15);
    }

    &__fast-link:hover &__circle:before {
        transform: scale(1.1)
    }
    
    &__prev-next {
        display: flex;
        justify-content: space-between;
        width: 80px;
        opacity: 0;
        transform: translateY(100px);
        transition: transform 800ms $easeInOutQuart, opacity 800ms $easeInOutQuart;

        @media screen and (max-width: 1024px) { width: 100%; justify-content: center; }
    }

    &__arrow {
        width: 20px;
        height: 20px;
        fill: none;
        stroke-width: 2px;
        stroke: $color7;
    }

    &__next-arrow {
        transform: rotate(180deg);
        @media screen and (max-width: 1024px) { margin-left: 30px; }
    }


    &__slider {
        width: calc(100% - var(--overlay-fast-links-close-dimension) - var(--overlay-fast-links-side-width));
        padding: 0 100px;
        opacity: 0;
        transform: translateY(100px);
        transition: transform 800ms $easeInOutQuart, opacity 800ms $easeInOutQuart;

        @media screen and (max-width: 1400px) { padding: 0 80px; }
        @media screen and (max-width: 1300px) { padding: 0 70px; }
        @media screen and (max-width: 1200px) { padding: 0 60px; }
        @media screen and (max-width: 1100px) { padding: 0 50px; }
        @media screen and (max-width: 1024px) { padding: 0; width: 100%; }
    }

    &__close-container {
        width: var(--overlay-fast-links-side-width);
    }

    &__close {
        position: relative;
        width: var(--overlay-fast-links-close-dimension);
        height: var(--overlay-fast-links-close-dimension);
        opacity: 0;
        transform: translateY(100px);
        transition: transform 800ms $easeInOutQuart, opacity 800ms $easeInOutQuart;

        @media screen and (max-width: 1024px) {
            position: absolute;
            top: 20px;
            right: 20px;
            transform: none;
        }
        @media screen and (max-width: 768px) {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }
    
    &__close:before, &__close:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 28px;
        top: 50%;
        left: 50%;
        background: #fff;
        z-index: 1;

        @media screen and (max-width: 1024px) { height: 12px; }
    }
    
    &__close:before { transform: translate(-50%, -50%) rotate(45deg);  }
    &__close:after  { transform: translate(-50%, -50%) rotate(-45deg); }
    
    &__close-circle {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        background: $color3;
        transition: transform 300ms $easeOutCubic;
    }

    &__close:hover &__close-circle {
        transform: scale(1.1);
    }
}
.rubric {
    background: transparent;
    overflow: hidden;
    transition: background 700ms;
    margin: 0 -30px 25px;
    position: relative;
    width: calc(100% + 60px);

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 30px;
        left: 30px;
        border: 1px solid rgba(40, 77, 69, 0.2);
        pointer-events: none;

        @media screen and (max-width: 768px) {
            left: 55px;
        }
    }

    &__header {
        display: block;
        padding-top: 40px;
        padding-bottom: 40px;
        cursor: pointer;
        @extend .x-padding-80;

        @media screen and (max-width: 600px)  { padding-top: 30px; padding-bottom: 30px; }
    }

    &__header--plus {
        padding: 0 25px 0 30px;
        display: flex;
        flex-direction: row;
        transition: padding 700ms $easeInOutCubic;

        @media screen and (max-width: 600px) { 
            padding-top: 30px;
            padding-bottom: 30px;
            padding-left: 75px; 
            transition: none;
        }
    }

    &__header--plus-no-image {
        padding: 0 25px 0 50px;

        @media screen and (max-width: 768px) {
            padding: 0 25px 0 75px;
        }

        @media screen and (max-width: 600px) {
            padding-top: 30px;
            padding-bottom: 30px;
            padding-left: 75px; 
            transition: none;
        }
    }

    &__header-img-container {
        width: 117px;

        @media screen and (max-width: 768px) {
            padding-left: 25px;
        }

        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    &__header-text {
        margin-left: 30px;
        padding: 31px 0px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 600px) {
            padding: 0;
        }
    }

    &__header--plus &__header-text {
        align-self: center;
    }

    &__surtitle {
        font-family: $font1;
        line-height: 1.5;
        letter-spacing: 1px;
        color: $color1;
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        transition: color 300ms $easeInOutCubic;

        @media screen and (max-width: 768px) {
            padding-bottom: 5px;
        }
    }

    &__header:hover &__surtitle {
        color: $color4;
    }

    &__title {
        padding-right: 50px;
        font-family: $font1;
        line-height: 1.5;
        color: $color1;
        transition: color 300ms $easeInOutCubic;
        font-size: 25px;

        @media screen and (max-width: 1024px) {
            padding-left: 25px;
            line-height: 1.2;
        }

        @media screen and (max-width: 768px) {
            padding-left: 75px;
            font-size: 22px;
        }

        @media screen and (max-width: 600px) {
            padding-right: 25px;
        }

        @media screen and (max-width: 400px) {
            font-size: 20px;
        }
    }

    &__title--plus {
        @media screen and (max-width: 1024px) {
            padding-left: 0;
        }
    }

    &__header:hover &__title {
        color: $color4;
    }

    &__icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        transition: transform 300ms $easeInOutCubic;
        background: #fff;
        position: absolute;
        left: 0;
        top: 30px;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            border: solid $color1 1px;
            transition: border 300ms $easeInOutCubic;
            border-radius: 50%;
            z-index: 1;
        }

        &::after {
            content: "";
            background: $color4;
            position: absolute;
            top: 100%;
            transition: top 300ms $easeInOutCubic;
            bottom: 0;
            right: 0;
            left: 0;
        }

        @media screen and (max-width: 1024px) {
            top: 25px;
        }

        @media screen and (max-width: 768px) {
            width: 50px;
            height: 50px;
            left: 30px;
            top: 28px;
        }

        @media screen and (max-width: 600px) {
            top: 18px;
        }
    }

    &__header:hover &__icon {
        &::after { top: 0%; }
        &::before { border: solid $color4 1px; }
    }

    &__icon--plus {
        @media screen and (max-width: 600px) {
            top: 25.5px;
        }
    }

    &__icon-triangle {
        width: 0; 
        height: 0; 
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 12px solid $color1;
        transition: border 400ms $easeInOutCubic;
        position: absolute;
        top: 50%;
        left: 50%; 
        transform: translate(-50%, -50%);
        z-index: 10;
    }

    &__header:hover &__icon-triangle {
        border-top: 12px solid #fff; 
    }

    &__content {
        height: 0;
        overflow: hidden;
        padding-left: 30px;
        padding-right: 30px;
    }

    &__transition {
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 700ms $easeInOutQuart, transform 700ms $easeInOutQuart;

        @media screen and (max-width: 768px) {
            padding-left: 30px;
        }

        @media screen and (max-width: 600px) {
            padding-left: 25px;
        }
    }
    
    &__transition > :first-child { margin-top: 0; }

    &__dynamic {
        h3 { @include responsiveFontSize(28, 20); margin: 30px 0; }
        h4 { @include responsiveFontSize(23, 18); margin: 30px 0; }
        p { @include responsiveFontSize(18, 15); margin: 30px 0 }
        pre { font-size: 13px; }
        @extend .x-padding-80;
    }

    // &__dynamic-only { padding-bottom: 75px; padding-top: 10px; }

    &__document, &__gallery, &__share {
        @extend .top-margin-50;
        @extend .x-padding-80;
    }

    &__document-only {
        @extend .bottom-padding-50; 
    }
    
    &__share {
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px solid rgba($color1, $alpha: 0.2);

        @media screen and (max-width: 500px) {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    &__share-dynamic-only { margin-top: 0px; }

    &__link-share:hover &__link-share-circle {
        border: solid $color4 1px;
    }

    &__link-share:hover &__svg-share {
        fill: $color4;
        stroke: $color4;
    }

    &__link-share:hover &__share-text {
        color: $color4;
    }

    &__link-share {
        display: inline-flex;
        align-items: center;
        justify-self: start;
        justify-content: flex-start;
    }

    &__link-share-circle {
        width: 45px;
        height: 45px;
        border: solid $color1 1px;
        transition: border 300ms $easeInOutCubic;
        border-radius: 50%;
        position: relative;

        @media screen and (max-width: 500px) {
            width: 35px;
            height: 35px;
            min-width: 35px;
            min-height: 35px;
        }

        @media screen and (max-width: 400px) {
            width: 25px;
            height: 25px;
            min-width: 25px;
            min-height: 25px;
        }
    }

    &__svg-share {
        width: 15px;
        height: 15px;
        fill: $color1;
        stroke: $color1;
        transition: fill 300ms $easeInOutCubic, stroke 300ms $easeInOutCubic;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        @media screen and (max-width: 400px) {
            width: 10px;
            height: 10px;
        }
    }

    &__share-text {
        font-family: $font1;
        font-weight: 700;
        line-height: 1.5;
        letter-spacing: 2px;
        font-size: 14px;
        margin-left: 20px;
        transition: color 300ms $easeInOutCubic;

        @media screen and (max-width: 500px) {
            font-size: 12px;
        }

        @media screen and (max-width: 400px) {
            font-size: 10px;
            margin-left: 5px;
        }
    }
}
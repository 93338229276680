/*
|--------------------------------------------------------------------------
| COMMON STYLES
|--------------------------------------------------------------------------
*/

@mixin huge-title() {
    font-family: $font1;
    font-weight: 700;
    line-height: 1;
    color: $color1; 
    @include responsiveFontSize(80, 35);
}

@mixin large-title() {
    font-family: $font1;
    font-weight: 500;
    line-height: 1.2;
    color: $color1;
    @include responsiveFontSize(50, 25);
}

@mixin medium-title() {
    font-family: $font1;
    font-weight: 500;
    line-height: 1;
    color: $color1;
    @include responsiveFontSize(30, 20);
}

@mixin small-title() {
    font-family: $font1;
    line-height: 1.4;
    color: $color1;
    @include responsiveFontSize(25, 18);
}

@mixin paragraph() {
    font-family: $font1;
    line-height: 1.7;
    color: $color1;
    @include responsiveFontSize(18, 15);
}

/*
|--------------------------------------------------------------------------
| FUNCTIONS
|--------------------------------------------------------------------------
*/
// Converti les pixels en Rem
@function rem($pixels, $context: $browser-context) {
    @return #{$pixels/$context}rem;
}

@function ratio($width, $height) {
    @return #{$height/$width * 100%};
}

// Créer une font responsive
@mixin responsiveFontSize($maxSize, $minSize, $maxViewport: 1440, $minViewport: 320) {
    $maxViewportPx: $maxViewport + px;
    $minViewportPx: $minViewport + px;

    $minSizeRem:     ($minSize     / $browser-context) + rem;
    $minViewportRem: ($minViewport / $browser-context) + rem;

    @media screen and (min-width: $maxViewportPx) {
        font-size: rem($maxSize);
    }

    @media screen and (min-width: $minViewportPx) and (max-width: $maxViewportPx) {
        font-size: calc(#{$minSizeRem} + (#{$maxSize} - #{$minSize}) * ((100vw - #{$minViewportRem}) / (#{$maxViewport} - #{$minViewport})));
    }

    @media screen and (max-width: $minViewportPx) {
        font-size: rem($minSize);
    }
}

// Créer une font responsive selon des valeurs définies
@mixin fontsize($base, $fs1440: 0, $fs1024: 0, $fs768: 0, $fs425: 0, $fs375: 0, $fs320: 0) {
    font-size: rem($base);
    @if $fs1440 != 0 { @media screen and (max-width: 1440px) { font-size: rem($fs1440); }}
    @if $fs1024 != 0 { @media screen and (max-width: 1024px) { font-size: rem($fs1024); }}
    @if $fs768 != 0  { @media screen and (max-width: 768px)  { font-size: rem($fs768);  }}
    @if $fs425 != 0  { @media screen and (max-width: 425px)  { font-size: rem($fs425);  }}
    @if $fs375 != 0  { @media screen and (max-width: 375px)  { font-size: rem($fs375);  }}
    @if $fs320 != 0  { @media screen and (max-width: 320px)  { font-size: rem($fs320);  }}
}

.news-banner {
    display: flex;
    justify-content: center;

    &__container {
        width: 100%;

        @media screen and (max-width: 768px) {
            padding-top: 25px;
        }
    }

    &__link {
        width: 100%;
    }

    &__content {
        max-width: 1200px;
        display: flex;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__content--detail {
        @media screen and (max-width: 768px) {
            flex-direction: column-reverse;
        }
    }

    &__img-container {
        width: 50%;
        max-height: 620px;
        overflow: hidden;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__img {
        transition: transform 500ms $easeInOutQuart;
    }

    &__link:hover &__img {
        transform: scale(1.1);
    }

    &__text-container {
        width: 50%;
        max-width: 720px;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__text {
        width: calc(100% + 100px);
        margin-left: -100px;
        margin-top: 115px;
        background: $color6;
        padding: 100px 115px 100px 100px;

        @media screen and (max-width: 1300px) {
            padding: 100px 80px 100px 80px;
        }

        @media screen and (max-width: 1100px) {
            padding: 80px 60px 80px 60px;
        }
        
        @media screen and (max-width: 768px) {
            width: 100%;
            margin-left: 0;
            margin-top: 0;
        }

        @media screen and (max-width: 600px) {
            padding: 50px;
        }

        @media screen and (max-width: 400px) {
            padding: 40px 30px;
        }
    }

    &__text--news {
        width: 100%;
        height: 620px;
        margin-left: 0;
        margin-top: 0;
        background: none;
        padding: 60px 0 60px 80px;

        @media screen and (max-width: 1100px) {
            padding: 60px 0 60px 60px;
        }
        
        @media screen and (max-width: 768px) {
            width: 100%;
            height: auto;
            padding-left: 0;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    &__category {
        margin-bottom: 90px;
        padding-top: 20px;
        border-top: 1px solid $color1;
        display: flex;

        @media screen and (max-width: 768px) {
            margin-bottom: 60px;
        }

        @media screen and (max-width: 600px) {
            margin-bottom: 30px;
        }
    }

    &__category-title, &__category-spacer, &__category-date {
        font-family: $font1;
        line-height: 1;
        color: $color1;
        font-size: 16px;

        @media screen and (max-width: 400px) {
            font-size: 13px;
        }
    }

    &__category-title {
        font-weight: 700;
    }

    &__category-spacer {
        margin: 0 12px;
    }

    &__title {
        font-family: $font1;
        line-height: 1.3;
        color: $color1;
        font-size: 55px;

        @media screen and (max-width: 600px) {
            font-size: 30px;
        }

        @media screen and (max-width: 400px) {
            font-size: 25px;
        }
    }

    &__title--news {
        font-weight: 700;

        @media screen and (max-width: 1400px) {
            padding-right: 150px;
        }

        @media screen and (max-width: 1300px) {
            padding-right: 120px;
        }

        @media screen and (max-width: 1200px) {
            padding-right: 100px;
        }

        @media screen and (max-width: 1100px) {
            padding-right: 80px;
        }

        @media screen and (max-width: 1000px) {
            padding-right: 60px;
        }

        @media screen and (max-width: 900px) {
            padding-right: 40px;
        }

        @media screen and (max-width: 800px) {
            padding-right: 20px;
        }

        @media screen and (max-width: 768px) {
            padding-right: 0;
        }
    }

    .smaller {
        font-size: 35px;

        @media screen and (max-width: 600px) {
            font-size: 30px;
        }

        @media screen and (max-width: 400px) {
            font-size: 25px;
        }
    }
}
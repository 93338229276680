.footer {
    &__container {
        display: flex;

        @media screen and (max-width: 900px) {
            padding-top: 0;
            flex-direction: column;
        }
    }

    &__logo-box {
        width: 35%;
        background: #fff;
        padding: 100px 120px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 1200px) {
            padding: 100px 100px;
        }

        @media screen and (max-width: 1024px) {
            padding: 100px 50px;
        }
        
        @media screen and (max-width: 900px) {
            width: 100%;
            padding: 100px 100px;
        }

        @media screen and (max-width: 768px) {
            padding: 75px 30px;
        }

        @media screen and (max-width: 600px) {
            padding: 75px 25px;
        }

        @media screen and (max-width: 500px) {
            padding: 50px 25px;
        }
    }

    &__logo {
        width: 250px;
        height: 135px;
        margin-bottom: 40px;

        @media screen and (max-width: 600px) {
            width: 200px;
            height: 110px;
        }
    }

    &__item-right {
        width: 65%;
        background: $color1;
        padding: 0 0 60px 100px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 1200px) {
            padding: 0 0 60px 75px;
        }

        @media screen and (max-width: 1100px) {
            padding: 0 0 50px 50px;
        }

        @media screen and (max-width: 900px) {
            width: 100%;
        }

        @media screen and (max-width: 768px) {
            padding: 0 0 50px 30px;
        }

        @media screen and (max-width: 768px) {
            padding: 0 0 50px 25px;
        }

        @media screen and (max-width: 600px) {
            flex-direction: column;
            padding: 0;
        }
    }

    &__left-box {
        max-width: 270px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 600px) {
            margin-bottom: 50px;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        margin-bottom: 90px;
        padding-top: 100px;
        padding-right: 25px;

        @media screen and (max-width: 1500px) {
            margin-bottom: 40px;
        }

        @media screen and (max-width: 900px) {
            margin-bottom: 90px;
        }
        
        @media screen and (max-width: 768px) {
            margin-bottom: 40px;
        }
        
        @media screen and (max-width: 600px) {
            padding-top: 50px;
            padding-left: 25px;
        }
    }

    &__link {
        font-family: $font1;
        line-height: 1;
        letter-spacing: 2px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 30px;
        text-transform: uppercase;
        transition: opacity 300ms $easeInOutCubic;

        &:hover {
            opacity: 0.5;
        }
    }

    &__link:last-child {
        margin-bottom: 0;
    }

    &__social-media {
        display: flex;

        @media screen and (max-width: 1500px) {
            flex-direction: column;
        }

        @media screen and (max-width: 900px) {
            flex-direction: row;
        }
        
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
        
        @media screen and (max-width: 600px) {
            flex-direction: row;
            padding-left: 25px;
        }
        
        @media screen and (max-width: 440px) {
            flex-direction: column;
        }
    }

    &__logo-seao-link {
        margin-right: 30px;

        @media screen and (max-width: 1500px) {
            margin-bottom: 30px;
        }
 
        @media screen and (max-width: 900px) {
            margin-bottom: 0;
        }
        
        @media screen and (max-width: 768px) {
            margin-bottom: 30px;
        }
        
        @media screen and (max-width: 500px) {
            margin-bottom: 0;
        }

        @media screen and (max-width: 440px) {
            margin-bottom: 30px;
        }
    }

    &__logo-lanaudiere-link {
        margin-right: 30px;
        transform: translateY(-8px);

        @media screen and (max-width: 1500px) {
            margin-bottom: 30px;
        }
 
        @media screen and (max-width: 900px) {
            margin-bottom: 0;
        }
        
        @media screen and (max-width: 768px) {
            margin-bottom: 30px;
        }
        
        @media screen and (max-width: 500px) {
            margin-bottom: 0;
        }

        @media screen and (max-width: 440px) {
            margin-bottom: 30px;
        }
    }

    &__logo-seao {
        width: 83px;
        height: 23px;
        transition: opacity 300ms $easeInOutCubic;

        &:hover {
            opacity: 0.5;
        }
    }

    &__logo-lanaudiere {
        width: 142px;
        height: auto;
        transition: opacity 300ms $easeInOutCubic;

        &:hover {
            opacity: 0.5;
        }
    }

    &__logo-facebook {
        width: 90px;
        height: 20px;
        transition: opacity 300ms $easeInOutCubic;

        &:hover {
            opacity: 0.5;
        }
    }

    &__svg-facebook {
        width: 100%;
        height: 100%;
        fill: #fff;
    }

    &__right-box {
        width: 375px;
        min-width: 375px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 1300px) {
            width: 350px;
            min-width: 350px;
        }

        @media screen and (max-width: 1200px) {
            width: 325px;
            min-width: 325px;
        }

        @media screen and (max-width: 1100px) {
            width: 300px;
            min-width: 300px;
        }

        @media screen and (max-width: 600px) { width: 100%; } 
    }

    &__scroll-to-top {
        padding: 80px 60px;
        background: $color7;

        @media screen and (max-width: 1300px) {

            padding: 80px 40px;
        }

        @media screen and (max-width: 1200px) {

            padding: 80px 30px;
        }

        @media screen and (max-width: 1100px) {

            padding: 80px 25px;
        }

        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    &__return-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__return-text {
        font-family: $font1;
        line-height: 1;
        color: #fff;
        font-size: 25px;
        text-align: center;
        transition: opacity 300ms $easeInOutCubic;
        margin-right: 15px;
    }

    &__return-content:hover &__return-text {
        opacity: 0.5;
    }

    &__return-circle {
        width: 54px;
        height: 54px;
        min-width: 54px;
        min-height: 54px;
        border-radius: 50%;
        background: $color1;
        position: relative;
        transform: rotate(180deg);
        transition: border 300ms $easeInOutCubic;

        &::after {
            content: "";
            width: 54px;
            height: 54px;
            border-radius: 50%;
            background: $color1;
            position: absolute;
            transition: transform 300ms $easeInOutCubic;
            z-index: -1;
        }
    }

    &__return-content:hover &__return-circle {
        &::after { transform: scale(1.1); }
    }

    &__return-triangle {
        width: 0; 
        height: 0; 
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 12px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%; 
        transform: translate(-50%, -50%);
    }

    &__copyright {
        display: flex;
        flex-direction: column;
        padding-right: 80px;

        @media screen and (max-width: 1024px) {
            padding-right: 50px;
        }

        @media screen and (max-width: 768px) {
            padding-right: 30px;
        }

        @media screen and (max-width: 600px) {
            padding-left: 25px;
            padding-right: 0;
            padding-top: 25px;
            padding-bottom: 25px;
            border-top: 1px solid rgba($color6, $alpha: 0.3); 
        }
    }

    &__blanko-link, &__copyright-montcalm {
        font-family: $font1;
        line-height: 1.5;
        color: #fff;
        font-size: 12px;
        transition: opacity 300ms $easeInOutCubic;
    }

    &__blanko-link { &:hover { opacity: 0.5; } }
}
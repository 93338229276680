.events-list {
    &__grid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-flow: row wrap;
        width: 100%;
    }

    &__gaps {
        width: calc(100% + 3% + 3%);
        margin-left: -3%;
        margin-right: -3%; 

        @media screen and (max-width: 900px) {
            width: calc(100% + 1% + 1%);
            margin-left: -1%;
            margin-right: -1%;  
        }

        @media screen and (max-width: 768px) {
            width: calc(100% + 3% + 3%);
            margin-left: -3%;
            margin-right: -3%;  
        }
    }
    
    &__column {
        padding-right: 3%;
        padding-left: 3%;

        @media screen and (max-width: 900px) {
            padding-right: 1%;
            padding-left: 1%;
        }

        @media screen and (max-width: 768px) {
            padding-right: 3%;
            padding-left: 3%;
        }
    }

    &__event {
        margin-bottom: 130px;

        @media screen and (max-width: 768px) {
            margin-bottom: 80px;
        }

        @media screen and (max-width: 600px) {
            margin-bottom: 60px;
        }
    }
}
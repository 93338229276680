.form {

    &__title {
        margin-bottom: 30px;
        @extend .medium-title;

        @media screen and (max-width: 1024px) { margin-bottom: 25px; }
        @media screen and (max-width: 768px)  { margin-bottom: 20px; }
    }

    &__title--top {
        width: 100%;
        margin-bottom: 50px;
        padding-left: 40px;
        @extend .medium-title;

        @media screen and (max-width: 1200px) { padding-left: 25px; }
        @media screen and (max-width: 1024px) { 
            padding-left: 15px;
            margin-bottom: 40px;
        }
        @media screen and (max-width: 900px) { padding-left: 5px; }
        @media screen and (max-width: 768px)  { margin-bottom: 30px; }
    }

    &__title--beforeInput {
        margin-bottom: 50px;

        @media screen and (max-width: 1024px) {
            margin-bottom: 40px;
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 30px;
        }
    }

    &__title--small {
        margin-top: var(--margin-top);
        margin-bottom: 30px;
        @extend .small-title;
        font-weight: 700;

        @media screen and (max-width: 1024px) { margin-bottom: 25px; }
        @media screen and (max-width: 768px)  { margin-bottom: 20px; }
    }

    &__title--big {
        margin-bottom: 50px;
        @extend .large-title;

        @media screen and (max-width: 1024px) { margin-bottom: 25px; }
        @media screen and (max-width: 768px)  { margin-bottom: 20px; }
    }

    &__title-and-note {
        @extend .large-title;  
    }

    &__pre { 
        margin: 10px 0;
        font: rem(13)/1.5 $font1;
        color: $color9;
        white-space: normal;
    }

    &__grid-gaps{
        width: calc(100% + 80px);
        margin-left: -40px;
        margin-right: -40px;
        
        @media screen and (max-width: 1200px) {
            width: calc(100% + 50px);
            margin-right: -25px;
            margin-left: -25px;
        }
        @media screen and (max-width: 1024px) {
            width: calc(100% + 30px);
            margin-right: -15px;
            margin-left: -15px;
        }
        @media screen and (max-width: 900px) {
            width: calc(100% + 10px);
            margin-right: -5px;
            margin-left: -5px;
        }
    }

    &__flex {
        display: flex;
        justify-content: center;    
    }
    
    &__column {
        padding-right: 40px;
        padding-left: 40px;

        @media screen and (max-width: 1200px) {
            padding-right: 25px;
            padding-left: 25px;
        }
        @media screen and (max-width: 1024px) {
            padding-right: 15px;
            padding-left: 15px;
        }
        @media screen and (max-width: 900px) {
            padding-right: 5px;
            padding-left: 5px;
        }
    }

    &__text { 
        @include paragraph();
        margin: 15px 0 25px;
        font-style: italic;
    }

    .js-radio-accompaniment,
    .js-radio-start-departure,
    .js-radio-start-destination, 
    .js-address-departure, 
    .js-stop-number-departure, 
    .js-address-destination, 
    .js-stop-number-destination,
    .js-address-departure-return, 
    .js-stop-number-departure-return, 
    .js-address-destination-return, 
    .js-stop-number-destination-return {
        visibility: hidden;
        height: 0;
        opacity: 0;
        transition: opacity 500ms $easeInOutCubic, height 500ms $easeInOutCubic;

        margin-bottom: 0;
    }

    .js-stop-number-departure,
    .js-stop-number-destination,
    .js-stop-number-departure-return,
    .js-stop-number-destination-return {
        margin-bottom: var(--margin-bottom);
    }

    .active-input {
        visibility: visible;
        height: auto;
        opacity: 1;
        transition: visibility 0ms, opacity 300ms $easeInOutCubic, height 300ms $easeInOutCubic;
        margin-top: 15px;
        --margin-top: 30px;
        --margin-bottom: 30px;
        --margin: 10px 0;
    }

    &__margin {
        margin-bottom: 60px;
    }

    &__specification {
        @include paragraph();
        margin-bottom: 50px;

        @media screen and (max-width: 1024px) { margin-bottom: 25px; }
        @media screen and (max-width: 768px)  { margin-bottom: 20px; }
        @media screen and (max-width: 600px)  { font-size: 13px; }
    }

    &__specification--note { 
        @include paragraph();
        color: $color9; 
        font-size: 13px;
        margin: var(--margin);
    }

    &__link {
        display: inline;
        @include paragraph();
        padding-bottom: 2px; 
        box-shadow: inset 0px -2px 0 $color3;
        transition: box-shadow 300ms $easeInOutCubic;

        &:hover { box-shadow: inset 0px -20px 0 $color3; }
    }

    .oc-loading {
        pointer-events: none;
    }
}
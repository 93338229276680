// Bouton normal
.btn {
    padding: 28px 32px;
    background: $color4;
    color: #fff;
    font-family: $font1;
    letter-spacing: 2px;
    line-height: 1;
    font-size: rem(14);
    font-weight: 700;
    text-transform: uppercase;
    transition: background 300ms, color 300ms;

    @media screen and (max-width: 768px) { font-size: rem(12); padding: 22px 25px 20px; }

    &:hover {
        background: $color7;
    }

    &--white {
        color: $color7;
        background: #fff;
    }

    &--white:hover {
        color: #fff;
        background: $color4;
    }

    &--white-hover:hover {
        color: $color7;
        background: #fff;
    }

    &.map {
        display: inline-flex;
        align-items: center;
        svg {
            fill: white;
            height: 16px;
            width: 16px;
            margin-left: 7px;
        }
    }
}

// Bouton calendrier
.btn-calendar {
    .disable { display: none; }

    &__container {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: $color4;
        box-shadow: 0px 0px 6px 6px rgba(0,0,0,0.03);
        position: fixed;
        right: 80px;
        bottom: 25px;
        transform: translateX(50%);
        z-index: 50;

        @media screen and (max-width: 1200px) { width: 70px; height: 70px; }
        @media screen and (max-width: 1024px) { right: 50px; width: 60px; height: 60px; }
        @media screen and (max-width: 768px)  { right: 40px; width: 50px; height: 50px; }

        @media screen and (max-height: 450px) { left: 25px; right: auto; transform: translateX(0); }

        &::before {
            content: "";
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: $color4;
            transform: scale(1);
            transition: transform 400ms $easeInOutCubic, box-shadow 400ms $easeInOutCubic;
            position: absolute;

            @media screen and (max-width: 1200px) { width: 70px; height: 70px; }
            @media screen and (max-width: 1024px) { width: 60px; height: 60px; }
            @media screen and (max-width: 768px)  { width: 50px; height: 50px; }
        }

        &:hover {
            &::before {
                transform: scale(1.1);
                box-shadow: 0px 0px 6px 6px rgba(0,0,0,0.03);
            }
        }
    }

    &__container--close {
        visibility: hidden;
    }

    &__svg-calendar {
        width: 30px;
        height: 30px;
        fill: #fff;
        visibility: visible;
        opacity: 1;
        transition: visibility 0ms, opacity 300ms $easeInOutCubic;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 768px) {
            width: 20px;
            height: 20px;
        }
    }

    &__X {
        width: 30px;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0ms, opacity 300ms $easeInOutCubic;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        @media screen and (max-width: 768px) {
            width: 15px;
        }
    }

    &__span--part-one, &__span--part-two {
        width: 30px;
        height: 2px;
        background: #fff;
        display: inline-block;
        position: absolute;

        @media screen and (max-width: 768px) {
            width: 15px;
        }
    }

    &__span--part-one {
        transform: rotate(-45deg);
    }

    &__span--part-two {
        transform: rotate(45deg);
    }
}

// Bouton menu
.btn-menu {
    position: relative;
    width: 30px;
    height: 20px;

    @media screen and (max-width: 768px) { width: 24px; height: 18px; }
    @media screen and (max-width: 425px) { width: 22px; height: 16px; }

    &__lines {
        position: absolute;
        width: 100%;
        height: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 768px) { height: 18px; }
        @media screen and (max-width: 425px) { height: 16px; }
    }

    &__line {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: $color1;
        opacity: 1;
        left: 0%;
        transform: rotate(0deg);
        transition: 250ms;
    }

    &__line1 { top: 0px; }
    &__line2, &__line3 { top: calc(50% - 1px); }
    &__line4 { bottom: 0px; }
}


.button-search {
    display: inline-flex;
    align-items: center;
    position: relative;

    @media screen and (max-width: 1024px) { width: 30px; height: 30px; }
    @media screen and (max-width: 768px)  { width: 25px; height: 25px; }
    @media screen and (max-width: 425px)  { width: 22px; height: 22px; }

    &__text {
        width: 83px;
        font: rem(16)/1.5 $font1;
        color: $color1;
        transition: color 300ms $easeInOutCubic;
    }

    &:hover &__text {
        color: $color3;
    }

    &__icons {
        @media screen and (min-width: 1025px) {
            position: relative;
            width: 20px;
            height: 20px;
            margin-left: 10px;
        }
    }

    &__svg {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        stroke: $color7;
        fill: none;
        stroke-width: 2px;
        transform: translate(-50%, -50%);
        transition: opacity 500ms;

        @media screen and (max-width: 1024px) { width: 30px; height: 30px; }
        @media screen and (max-width: 768px)  { width: 25px; height: 25px; }
        @media screen and (max-width: 425px)  { width: 22px; height: 22px; }
    }

    &__x {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 500ms;

        &:before, &:after {
            content: '';
            position: absolute;
            width: 20px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: $color7;

            @media screen and (max-width: 1024px) and (min-width: 600px) { width: 25px; }
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
    }
}


:root {
    --sticky-buttons-spaces: 40px;
    --sticky-buttons-spaces-between: 10px;
    --sticky-buttons-dimensions: 90px;

    @media screen and (max-width: 1200px) { --sticky-buttons-dimensions: 80px; }
    @media screen and (max-width: 1024px) { --sticky-buttons-dimensions: 60px; --sticky-buttons-spaces: 30px; }
    @media screen and (max-width: 768px)  { --sticky-buttons-dimensions: 50px; --sticky-buttons-spaces: 20px; --sticky-buttons-spaces-between: 5px; }
}

// Bouton liens rapides
.btn-fast-links {
    position: relative;
    width: var(--sticky-buttons-dimensions);
    height: var(--sticky-buttons-dimensions);
    border-radius: 50%;
    background: $color3;
    box-shadow: 0px 0px 6px 6px rgba(0,0,0,0.03);
    pointer-events: all;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        background: $color3;
        transition: transform 400ms $easeOutCubic, box-shadow 400ms $easeOutCubic;
    }

    &:hover:before {
        transform: scale(1.1);
        box-shadow: 0px 0px 6px 6px rgba(0,0,0,0.03);
    }

    &__svg {
        width: 26px;
        height: 26px;
        fill: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 1024px) { width: 22px; height: 22px; }
        @media screen and (max-width: 768px)  { width: 20px; height: 20px; }
    }
}

// Bouton patte
.btn-patte {
    position: relative;
    width: var(--sticky-buttons-dimensions);
    height: var(--sticky-buttons-dimensions);
    margin-top: var(--sticky-buttons-spaces-between);
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 6px 6px rgba(0,0,0,0.03);
    pointer-events: all;

    &:before {
        content: "";
        width: var(--sticky-buttons-dimensions);
        height: var(--sticky-buttons-dimensions);
        border-radius: 50%;
        background: #fff;
        transform: scale(1);
        transition: transform 400ms $easeOutCubic, box-shadow 400ms $easeOutCubic;
        position: absolute;
    }

    &:hover:before {
        transform: scale(1.1);
        box-shadow: 0px 0px 6px 6px rgba(0,0,0,0.03);
    }

    &__picture {
        width: 38px;
        height: 38px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        @media screen and (max-width: 1024px) { width: 32px; height: 32px; }
        @media screen and (max-width: 768px)  { width: 30px; height: 30px; }
    }
}

// Bouton recuperation
.btn-recuperation {
    position: relative;
    width: var(--sticky-buttons-dimensions);
    height: var(--sticky-buttons-dimensions);
    margin-top: var(--sticky-buttons-spaces-between);
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 6px 6px rgba(0,0,0,0.03);
    pointer-events: all;

    &:before {
        content: "";
        width: var(--sticky-buttons-dimensions);
        height: var(--sticky-buttons-dimensions);
        border-radius: 50%;
        background: #fff;
        transform: scale(1);
        transition: transform 400ms $easeOutCubic, box-shadow 400ms $easeOutCubic;
        position: absolute;
    }

    &:hover:before {
        transform: scale(1.1);
        box-shadow: 0px 0px 6px 6px rgba(0,0,0,0.03);
    }

    &__svg {
        width: 38px;
        height: 38px;
        fill: $color7;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        @media screen and (max-width: 1024px) { width: 32px; height: 32px; }
        @media screen and (max-width: 768px)  { width: 30px; height: 30px; }
    }
}

// Bouton transport
.btn-transport {
    position: relative;
    width: var(--sticky-buttons-dimensions);
    height: var(--sticky-buttons-dimensions);
    margin-top: var(--sticky-buttons-spaces-between);
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 6px 6px rgba(0,0,0,0.03);
    pointer-events: all;

    &:before {
        content: "";
        width: var(--sticky-buttons-dimensions);
        height: var(--sticky-buttons-dimensions);
        border-radius: 50%;
        background: #fff;
        transform: scale(1);
        transition: transform 400ms $easeOutCubic, box-shadow 400ms $easeOutCubic;
        position: absolute;
    }

    &:hover:before {
        transform: scale(1.1);
        box-shadow: 0px 0px 6px 6px rgba(0,0,0,0.03);
    }

    &__svg {
        width: 38px;
        height: 38px;
        fill: $color7;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        @media screen and (max-width: 1024px) { width: 32px; height: 32px; }
        @media screen and (max-width: 768px)  { width: 30px; height: 30px; }
    }
}

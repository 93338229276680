.results-number {
    padding: 15px 15px 35px 15px;
    &__text, &__number {
        font-family: $font1;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.7;
        color: $color1;
        text-align: center;

        @media screen and (max-width: 600px) {
            font-size: 15px;
        }

        @media screen and (max-width: 400px) {
            font-size: 13px;
        }
    }
}
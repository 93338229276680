/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-fast-links {
    .overlay-fast-links {
        pointer-events: all;
        visibility: visible;
        
        &__background { background: rgba(#000, 0.4); }

        &__title-container:before {
            right: calc(50% + 150px);

            @media screen and (max-width: 1024px) { right: calc(50% + 120px); }
            @media screen and (max-width: 768px) { right: calc(50% + 110px); }
            @media screen and (max-width: 500px) { right: calc(50% + 100px); }
        }
        
        &__title-container:after {
            left: calc(50% + 150px);

            @media screen and (max-width: 1024px) { left: calc(50% + 120px); }
            @media screen and (max-width: 768px) { left: calc(50% + 110px); }
            @media screen and (max-width: 500px) { left: calc(50% + 100px); }
        }

        &__close, &__prev-next, &__slider {
            opacity: 1;
            transform: none;
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-fast-links.closing-overlay-fast-links {
    .overlay-fast-links {
        &__background { background: rgba(#000, 0); }

        &__title-container:before {
            right: 100%;
            transition: right 800ms $easeOutQuart;
        }
        
        &__title-container:after {
            left: 100%;
            transition: left 800ms $easeOutQuart;
        }

        &__close, &__prev-next, &__slider {
            opacity: 0;
            transform: translateY(100px);
        }

        &__close {
            @media screen and (max-width: 1024px) {
                transform: none;
            }
        }
    }
}
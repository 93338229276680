.event-link {
    &__img-container {
        margin-top: 38px;
        position: relative; 
    }
    
    &__picture {
        position: relative;
        overflow: hidden;
    }
    
    &__img {
        transition: transform 500ms $easeInOutQuart;
    }

    &__picture-transition { position: relative; }
    
    &__picture-transition:after {
        content: '';
        position: absolute;
        top: 0%;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color7;
        z-index: 1;
        transition: top 1000ms $easeInOutQuint;
    }

    &__picture-transition-1:after { transition-delay: 100ms; }
    &__picture-transition-2:after { transition-delay: 200ms; }
    &__picture-transition-3:after { transition-delay: 300ms; }
    &__picture-transition-4:after { transition-delay: 400ms; }
    &__picture-transition-5:after { transition-delay: 500ms; }
    
    &__picture-transition[data-scrollfire="fire"]:after { top: 100%; }

    &__picture:before, &__picture:after {
        position: absolute;
        opacity: 0;
        z-index: 1;
        transition: opacity 400ms;
    }

    &__picture:before {
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($color7, 0.4);
    }

    &__picture:after {
        content: 'Lire la suite';
        top: 50%;
        left: 50%;
        font: 700 rem(14)/1 $font1;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: translate(-50%, -50%);
    }

    &:hover &__picture:before, &:hover &__picture:after {
        opacity: 1;
    }
    
    &:hover &__img{
        transform: scale(1.1);
    }
    
    &__date {
        position: absolute;
        top: 0;
        right: 20px;
        font: 700 rem(76)/1 $font1;
        color: $color4;
        z-index: 2;
        transform: translateY(-50%);
    
        @media screen and (max-width: 1300px) { font-size: 50px; }
        @media screen and (max-width: 900px)  { font-size: 40px; }
        @media screen and (max-width: 500px)  { font-size: 50px; }
    }
    
    &__content {
        padding-top: 45px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    
        @media screen and (max-width: 1400px) {
            padding-top: 30px;
            padding-right: 80px;
        }
    
        @media screen and (max-width: 1200px) {
            padding-right: 60px;
        }
    
        @media screen and (max-width: 1024px) {
            padding-top: 20px;
            padding-right: 40px;
        }
    
        @media screen and (max-width: 600px) {
            padding-right: 20px;
        }
    }
    
    &__surtitle {
        font-family: $font1;
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
        color: $color1;
        
        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }
    
    &__title {
        font-family: $font1;
        font-weight: 700;
        font-size: 25px;
        line-height: 1.2;
        color: $color1;
        padding: 25px 0 40px;
    
        
        @media screen and (max-width: 1300px) {
            padding: 25px 0 30px;
        }
    
        @media screen and (max-width: 1200px) {
            font-size: 20px;
        }
    
        @media screen and (max-width: 1024px) {
            padding: 25px 0 20px;
        }
    
        @media screen and (max-width: 600px) {
            font-size: 18px;
            padding: 20px 0;
        }
    }
    
    &__content-date {
        font-family: $font1;
        font-size: 16px;
        line-height: 1;
        color: $color1;
    
        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }
}


.featured-events {
    --featured-events-header-width: 715px;
    --featured-events-header-icon-container-dimension: 70px;
    --featured-events-header-title-padding-left: 25px;
    --featured-events-slider-padding-left: 120px;
    
    @media screen and (max-width: 1400px) {
        --featured-events-header-width: 650px;
        --featured-events-slider-padding-left: 100px;
    }

    @media screen and (max-width: 1200px) {
        --featured-events-header-width: 600px;
        --featured-events-slider-padding-left: 50px;
        --featured-events-header-title-padding-left: 20px;
    }
    @media screen and (max-width: 1024px) {
        --featured-events-header-width: 400px;
        --featured-events-slider-padding-left: 30px;
    }
}

.featured-events {
    &__container {
        display: flex;
        align-items: center;
        background: $color12;

        @media screen and (max-width: 768px) { flex-flow: row wrap; }
    }
    
    &__header {
        position: relative;

        @media screen and (max-width: 768px) { width: 100%; }
    }

    &__picture-transition {
        position: relative;
    }
    
    &__picture-transition:after {
        content: '';
        position: absolute;
        top: 0%;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color7;
        z-index: 1;
        transition: top 1000ms $easeInOutQuint;
    }

    &__picture-transition[data-scrollfire="fire"]:after { top: 100%; }
    
    &__picture {
        position: relative;
        width: var(--featured-events-header-width);
        padding-top: ratio(715, 775);

        @media screen and (max-width: 1024px) { padding-top: 0; height: 600px; }
        @media screen and (max-width: 768px) { width: 100%; height: 400px; }
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__text-container {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
    }

    &__title-container {
        display: flex;
        align-items: center;

        @media screen and (max-width: 1024px) { flex-flow: row wrap; }
    }

    &__icon-container {
        position: relative;
        width: var(--featured-events-header-icon-container-dimension);
        height: var(--featured-events-header-icon-container-dimension);
        border-radius: 50%;
        border: 1px solid #fff;
    }
    
    &__svg {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 50%;
        fill: #fff;
        transform: translate(-50%, -50%);
    }
    
    &__title {
        flex: 1;
        padding-left: var(--featured-events-header-title-padding-left);
        @extend .large-title;
        color: #fff;
        white-space: nowrap;
        
        @media screen and (max-width: 1400px) { padding-left: 20px; }
        @media screen and (max-width: 1024px) and (min-width: 769px) { width: 100%; margin-top: 20px; padding-left: 0; flex: initial; }
        @media screen and (max-width: 500px) { width: 100%; margin-top: 20px; padding-left: 0; flex: initial; }
    }

    &__button {
        margin-left: calc(var(--featured-events-header-icon-container-dimension) + var(--featured-events-header-title-padding-left));
        white-space: nowrap;
        @extend .top-margin-60;

        @media screen and (max-width: 1024px) and (min-width: 769px) { margin-left: 0; }
        @media screen and (max-width: 500px) { margin-left: 0; }
    }
    
    &__slider-container {
        width: calc(100% - var(--featured-events-header-width));
    
        @media screen and (max-width: 768px) { width: 100%; }
    }

    &__slider {
        padding-left: var(--featured-events-slider-padding-left);
    }

    &__slider-slide {
        .event-link {
            width: 350px;
            margin-right: 60px;

            @media screen and (max-width: 1400px) { margin-right: 50px; }
            @media screen and (max-width: 1200px) { margin-right: 30px; width: 320px; }
            @media screen and (max-width: 1024px) { margin-right: 20px; width: 300px; }
            @media screen and (max-width: 768px)  { margin-top: 10px; }
            @media screen and (max-width: 425px)  { margin-right: 15px; width: 280px; }
        }
    }

    // Slider nav
    .slider-nav {
        padding: 0 120px 0 var(--featured-events-slider-padding-left);
        @extend .top-margin-60;

        @media screen and (max-width: 1600px) { padding-right: var(--x-padding-120); }
    }

    .slider-nav--event {
        @media screen and (max-width: 768px) {
            padding-bottom: 48px;
        }
    }
}
:root {
    --banner-bottom-height: 90px;
    --banner-padding-right: 80px;
    --banner-count: 2;

    @media screen and (max-width: 1024px), screen and (max-height: 800px) { --banner-bottom-height: 80px; --banner-padding-right: 50px; }
    @media screen and (max-width: 768px), screen and (max-height: 700px)  { --banner-bottom-height: 70px; --banner-padding-right: 30px; }
    @media screen and (max-width: 600px), screen and (max-height: 600px)  { --banner-padding-right: 0; }
    @media screen and (max-width: 425px)  { --banner-bottom-height: 60px; }
}

.banner {
    &__container {
        position: relative;
        height: calc((var(--vh, 1vh) * 100) - var(--header-height));
        min-height: calc(400px + var(--banner-bottom-height));

        @media screen and (max-width: 600px) { min-height: calc(400px + (var(--banner-bottom-height) * var(--banner-count))); }
    }

    &__container:before {
        content: '';
        position: absolute;
        width: var(--banner-padding-right);
        top: 50%;
        right: 0;
        bottom: 0;
        background: $color4;
    }

    &__primary {
        width: calc(100% - var(--banner-padding-right));
    }

    &__slider {
        margin-bottom: 0px !important; // override plugin margin
    }

    &__slider-slide {
        position: relative;
    }

    &__media {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    &__media::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0.3);
        z-index: 2;
    }

    &__picture,
    &__video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    &--default &__picture {
        @media screen and (min-width: 601px) {
            display: none;
        }
    }

    &--default &__video {
        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    &__content {
        display: flex;
        position: relative;
        align-items: center;
        height: calc((var(--vh, 1vh) * 100) - var(--header-height) - var(--banner-bottom-height));
        min-height: 400px;
        @extend .x-padding-120;
        z-index: 2;

        @media screen and (max-width: 600px) { height: calc((var(--vh, 1vh) * 100) - var(--header-height) - (var(--banner-bottom-height) * var(--banner-count))); }
    }

    &__text {
        max-width: 750px;

        @media screen and (max-width: 1200px) { max-width: 650px; }
        @media screen and (max-width: 900px)  { max-width: 550px; }
        @media screen and (max-width: 700px)  { max-width: 500px; }
    }

    &__title {
        @extend .huge-title;
        color: #fff;
    }

    &__button {
        @extend .top-margin-50;
    }

    &__secondary {
        display: flex;
        justify-content: flex-end;
        flex-flow: row wrap;
        height: var(--banner-bottom-height);

        @media screen and (max-width: 600px) { height: auto; }
    }

    &__video-link-container {
        position: absolute;
        bottom: 25px;
        left: var(--x-padding-120);

        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    &__video-link {
        display: inline-flex;
        align-items: center;
        gap: 0 20px;
        transition: opacity 300ms;
    }

    &__video-link:hover {
        opacity: 0.6;
    }

    &__video-link-icon {
        width: 0;
        height: 0;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        border-left: 13.5px solid #fff;
        border-radius: 3px;
    }

    &__video-link-text {
        font-family: $font1;
        font-size: 1.3rem;
        font-weight: 600;
        color: white;
    }

    // Slider nav
    .slider-nav {
        flex: 1;
        @extend .x-padding-120;

        @media screen and (max-width: 600px) {
            flex: initial;
            width: 100%;
            height: var(--banner-bottom-height);
        }
    }

    // Scroll to next block
    &__scroll {
        display: flex;

        @media screen and (max-width: 600px) { width: 100%; }
    }

    &__scroll:hover &__scroll-svg-container {
        background: $color14;
    }

    &__scroll-svg-container {
        position: relative;
        background: $color13;
        width: var(--banner-bottom-height);
        height: var(--banner-bottom-height);
        transition: background 300ms;
    }

    &__scroll-svg {
        position: absolute;
        width: 20px;
        height: 30px;
        top: 50%;
        left: 50%;
        fill: #fff;
        transform: translate(-50%, -50%);
        transition: transform $easeInOutCubic;

        animation-name: bounce;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }

    @keyframes bounce {
        0%  { transform: translate(-50%,-50%); }
        50% { transform: translate(-50%,-25%); }
        100% { transform: translate(-50%,-50%); }
    }

    &__scroll-title {
        margin-right: var(--banner-padding-right);
        padding: 0 50px;
        height: var(--banner-bottom-height);
        font: 700 rem(18)/var(--banner-bottom-height) $font1;
        color: #fff;
        background: $color4;

        @media screen and (max-width: 1024px) { padding: 0 40px; }
        @media screen and (max-width: 768px)  { padding: 0 30px; }
        @media screen and (max-width: 600px)  { flex: 1; }
    }
}

:root {
    --header-secondary-nav-height: 40px;
    --header-primary-nav-height: 100px;
    --header-x-padding: 40px;
    --header-height: calc(var(--header-primary-nav-height) + var(--header-secondary-nav-height));

    @media screen and (max-width: 1200px) {
        --header-primary-nav-height: 80px;
    }
    @media screen and (max-width: 1024px) {
        --header-height: 90px;
        --header-x-padding: 50px;
    }
    @media screen and (max-height: 600px) {
        --header-height: 80px;
    }
    @media screen and (max-width: 768px) {
        --header-height: 80px;
        --header-x-padding: 30px;
    }
    @media screen and (max-width: 600px) {
        --header-x-padding: 25px;
    }
    @media screen and (max-width: 500px) {
        --header-x-padding: 25px;
    }
}

.header {
    position: relative;
    background: #fff;
    z-index: 48;

    @media screen and (max-width: 1024px), screen and (max-height: 600px) { display: none; }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__links {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: var(--header-secondary-nav-height);
        padding: 0 var(--header-x-padding);
        border-bottom: 1px solid rgba($color1, 0.2);
    }

    &__links-right {
        display: flex;
        justify-content: flex-end;
    }

    &__link {
        font-family: $font1;
        line-height: 1.5;
        color: $color1;
        font-size: 12px;
        opacity: 1;
        transition: opacity 300ms $easeOutCubic;
        margin-right: 30px;

        &:hover {
            opacity: 0.5;
        }
    }

    &__link-facebook {
        width: 12px;
        height: 18px;
        opacity: 1;
        transition: opacity 300ms $easeOutCubic;

        &:hover {
            opacity: 0.5;
        }
    }

    &__svg-facebook {
        width: 100%;
        height: 100%;
    }

    &__menu-links {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        height: var(--header-primary-nav-height);
        padding: 0 var(--header-x-padding);
    }

    &__menu-link {
        font: rem(16)/var(--header-primary-nav-height) $font1;
        color: $color1;
        transition: color 300ms $easeOutCubic;
        margin-right: 60px;
        padding-right: 15px;
        position: relative;

        &:hover {
            color: $color3;
        }

        @media screen and (max-width: 1400px) { margin-right: 40px; }
        @media screen and (max-width: 1200px) { margin-right: 25px; }
    }

    &__menu-link:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        right: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 7px solid $color3;
        transition: transform 300ms $easeOutCubic;
        transform: translateY(-50%);

        @media screen and (max-width: 1100px) { right: 0; }
    }

    &__menu-link-no-arrow {
        font: rem(16)/var(--header-primary-nav-height) $font1;
        color: $color1;
        transition: color 300ms $easeOutCubic;
        margin-right: 60px;
        padding-right: 15px;
        position: relative;

        &:hover {
            color: $color3;
        }

        @media screen and (max-width: 1400px) { margin-right: 40px; }
        @media screen and (max-width: 1200px) { margin-right: 25px; }
    }

    &__menu-link-no-arrow:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        right: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        transition: transform 300ms $easeOutCubic;
        transform: translateY(-50%);

        @media screen and (max-width: 1100px) { right: 0; }
    }

    &__logo-link {
        position: relative;
        transform: translateY(-15px);

        @media screen and (max-width: 1200px) { transform: translateY(-10px); }
    }

    &__logo-link:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0px;
        width: 80px;
        height: 50px;
        background: #fff;
        z-index: -1;

        @media screen and (max-width: 1200px) { width: 71px; }
    }

    &__logo {
        width: 180px;
        height: auto;

        @media screen and (max-width: 1200px) { width: 150px; }
    }

    &__menu-right &__menu-link {
        @media screen and (max-width: 1024px) {
            margin-right: 35px;
        }
    }

    &__svg-facebook {
        fill: $color1;
        stroke: $color1;
    }
}

.header-mobile {
    display: none;
    position: relative;
    background: #fff;
    z-index: 48;

    @media screen and (max-width: 1024px), screen and (max-height: 600px) { display: block; }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: var(--header-height);
        padding: 0 var(--header-x-padding);
    }

    &__logo {
        width: 145px;
        height: auto;

        @media screen and (max-width: 768px) { width: 125px; }
        @media screen and (max-width: 500px) { width: 110px; }
    }
}

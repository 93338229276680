// Slider nav
.slider-nav {
    display: flex;
    align-items: center;
    background: $color12;

    &__link {
        font-size: 0;
        transition: opacity 300ms;
    }

    &__link:hover {
        opacity: 0.6;
    }

    &__prev {
        margin-right: 50px;

        @media screen and (max-width: 1200px) { margin-right: 40px; }
        @media screen and (max-width: 1024px) { margin-right: 30px; }
        @media screen and (max-width: 768px)  { margin-right: 25px; }
    }
    
    &__next-svg {
        transform: rotate(180deg);
    }
    
    &__svg {
        width: 20px;
        height: 20px;
        fill: none;
        stroke-width: 2px;
        stroke: $color7;
    }

    &__dots {
        position: relative;
        width: 100%;
        margin-left: 60px;

        @media screen and (max-width: 1200px) { margin-left: 50px; }
        @media screen and (max-width: 1024px) { margin-left: 40px; }
        @media screen and (max-width: 768px)  { margin-left: 30px; }

        ul {
            position: relative;
            display: flex;
            bottom: auto;
            align-items: center;
            
            li {
                flex: 1;
                margin: 0;
                height: 1px;
                background: rgba($color7, 0.2);
                transition: height 300ms, background 300ms;

                &.slick-active {
                    height: 3px;
                    background: $color7;
                }

                button { display: none; }
            }
        }
    }
}
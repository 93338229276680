/*** ----- IMPORTS  ----- ***/
@import '../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import '../libraries/overlay.min.css';

@import 'base/variables';
@import 'base/mixins';
@import 'base/resets';
@import 'base/grid';
@import 'base/fonts';
@import 'base/dynamic';
@import 'base/base';

@import 'layouts/layouts';

@import 'modules/modules';
@import 'modules/header';
@import 'modules/footer';
@import 'modules/banner';
@import 'modules/news';
@import 'modules/breadcrumb';
@import 'modules/page-title';
@import 'modules/intro';
@import 'modules/buttons';
@import 'modules/rubrics';
@import 'modules/documents';
@import 'modules/gallery';
@import 'modules/section';
@import 'modules/form';
@import 'modules/field';
@import 'modules/calendar';
@import 'modules/pagination';
@import 'modules/vitrine';
@import 'modules/alerts';
@import 'modules/event-banner';
@import 'modules/news-banner';
@import 'modules/contact-banner';
@import 'modules/events-list';
@import 'modules/event';
@import 'modules/slider-nav';
@import 'modules/scrollfire';
@import 'modules/result-block';
@import 'modules/results-number';
@import 'modules/overlays/search';
@import 'modules/overlays/fast-links';
@import 'modules/overlays/dropdown';
@import 'modules/overlays/mobile-menu';
@import 'modules/overlays/calendar';
@import 'modules/overlays/share';
@import 'modules/nothing-to-display';
@import 'modules/transitions';
@import 'modules/map';

@import 'states/states';
@import 'states/overlays/dropdown';
@import 'states/overlays/calendar';
@import 'states/overlays/fast-links';
@import 'states/overlays/mobile-menu';
@import 'states/overlays/search';
@import 'states/overlays/share';

@import 'themes/themes';

@import '../node_modules/slick-carousel/slick/slick-theme.scss';
@import '../node_modules/slick-carousel/slick/slick.scss';

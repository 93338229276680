.event-banner {
    &__container {
        padding-top: 40px;
        padding-bottom: 90px;

        @media screen and (max-width: 500px) {
            padding-top: 20px;
            padding-bottom: 50px;
        }
    }

    &__container--event {
        padding-top: 0;
        padding-bottom: 120px;

        @media screen and (max-width: 1400px) {
            padding-bottom: 60px;
        }
    }

    &__link {
        display: block;
        position: relative;
    }

    &__picture {
        position: relative;
        z-index: -1;
        height: 815px;
        overflow: hidden;

        @media screen and (max-width: 1500px) {
            height: 715px;
        }

        @media screen and (max-width: 1300px) {
            height: 615px;
        }

        @media screen and (max-width: 1100px) {
            height: 515px;
        }

        @media screen and (max-width: 900px) {
            height: 415px;
        }

        @media screen and (max-width: 600px) {
            height: 315px;
        }

        @media screen and (max-width: 400px) {
            height: 215px;
        }
    }

    &__img {
        transition: transform 500ms $easeInOutQuart;
    }

    &__link:hover &__img {
        transform: scale(1.1);
    }

    &__date {
        font-family: $font1;
        font-weight: 700;
        font-size: 160px;
        line-height: 0;
        color: $color4;
        position: absolute;
        right: 65px;
        top: 0;
        z-index: 47;

        @media screen and (max-width: 1200px) {
            font-size: 100px;
        }

        @media screen and (max-width: 800px) {
            font-size: 80px;
        }

        @media screen and (max-width: 600px) {
            font-size: 60px;
        }

        @media screen and (max-width: 400px) {
            font-size: 40px;
            right: 20px;
        }
    }

    &__date--multiple {
        font-size: 120px;

        @media screen and (max-width: 1200px) {
            font-size: 100px;
        }

        @media screen and (max-width: 800px) {
            font-size: 80px;
        }

        @media screen and (max-width: 600px) {
            font-size: 60px;
        }

        @media screen and (max-width: 400px) {
            font-size: 40px;
            right: 20px;
        }
    }

    &__content {
        width: 82%;
        padding: 80px 300px 80px 90px;
        background-color: $color6;
        margin-top: -245px;
        z-index: 1;

        @media screen and (max-width: 1500px) {
            margin-top: -145px;
        }

        @media screen and (max-width: 1400px) {
            padding-right: 200px;
        }

        @media screen and (max-width: 1300px) {
            padding-right: 90px;
        }

        @media screen and (max-width: 1200px) {
            padding: 50px 50px;
        }
        
        @media screen and (max-width: 1000px) {
            padding: 25px 25px;
        }
        
        @media screen and (max-width: 900px) {
            margin-top: -100px;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 0;
        }
    }

    &__category {
        border-top: 1px solid $color1;
        padding-bottom: 100px;
        padding-top: 25px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 900px) {
            padding-bottom: 50px;
        }
    }

    &__category-title, &__category-spacers, &__category-date {
        font-family: $font1;
        font-size: 16px;
        color: $color1;

        @media screen and (max-width: 400px) {
            font-size: 13px;
        }
    }

    &__category-title {
        font-weight: 700;
    }

    &__category-spacers {
        padding: 0 12px;
    }

    &__surtitle {
        font-family: $font1;
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
        color: $color1;
    
        @media screen and (max-width: 400px) {
            font-size: 14px;
        }
    }

    &__title {
        font-family: $font1;
        font-weight: 700;
        font-size: 40px;
        line-height: 1.3;
        color: $color1;
        padding-top: 30px;
        padding-bottom: 60px;

        @media screen and (max-width: 1000px) {
            font-size: 30px;
        }

        @media screen and (max-width: 900px) {
            padding-bottom: 30px;
        }

        @media screen and (max-width: 800px) {
            font-size: 25px;
        }

        @media screen and (max-width: 400px) {
            font-size: 20px;
            padding: 20px 0;
        }
    }

    &__content-date {
        font-family: $font1;
        font-size: 16px;
        line-height: 1;
        color: $color1;
        
        @media screen and (max-width: 400px) {
            font-size: 14px;
        }
    }  
}
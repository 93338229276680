// Documents normal
.documents {
    .document {
        position: relative;
        margin-bottom: 25px;

        @media screen and (max-width: 768px) {
            padding-left: 25px;   
        }

        &__link {
            display: block;
            position: relative;
            padding-top: 40px;
            padding-bottom: 40px;
            cursor: pointer;
            border: 1px solid rgba($color1, 0.2);
            @extend .x-padding-80;

            @media screen and (max-width: 600px)  { padding-top: 30px; padding-bottom: 30px; }
        }

        &__link--plus {
            display: flex;
            flex-direction: row;
            padding: 0 25px 0 0;

            @media screen and (max-width: 600px) { 
                padding-top: 30px;
                padding-bottom: 30px;
                padding-left: 20px; 
                transition: none;
            }
        }

        &__link--jobs {
            padding: 0 45px 0 15px;
            justify-content: space-between;

            @media screen and (max-width: 600px) {
                flex-direction: column;
            }
        }

        &__img-container {
            width: 117px;

            @media screen and (max-width: 768px) {
                width: 87px;
            }

            @media screen and (max-width: 600px) {
                display: none;
            }
        }

        &__text {
            margin-left: 30px;
            padding: 31px 0px;
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 600px) {
                padding: 0;
            }
        }

        &__text--jobs { @media screen and (max-width: 600px) { padding: 31px 0px; } }

        &__text-no-image {
            margin-left: 80px;

            @media screen and (max-width: 1024px) {
                margin-left: 50px;
            }

            @media screen and (max-width: 768px) {
                margin-left: 60px;
            }

            @media screen and (max-width: 600px) {
                margin-left: 30px;
            }
        }

        &__surtitle {
            font-family: $font1;
            line-height: 1.5;
            letter-spacing: 1px;
            color: $color1;
            font-weight: 700;
            font-size: 12px;
            text-transform: uppercase;
            transition: color 300ms $easeInOutCubic;
            
            @media screen and (max-width: 768px) {
                padding-bottom: 5px;
            }
        }

        &__link:hover .document__surtitle {
            color: $color4;
        }
    
        &__title {
            padding-right: 50px;
            font-family: $font1;
            line-height: 1.5;
            color: $color1;
            transition: color 300ms $easeInOutCubic;
            font-size: 25px;

            @media screen and (max-width: 1024px) {
                line-height: 1;
            }

            @media screen and (max-width: 768px) {
                padding-left: 30px;  
                font-size: 22px; 
            }
            
            @media screen and (max-width: 600px) {
                padding-left: 25px;   
            }

            @media screen and (max-width: 400px) {
                font-size: 20px;
            }
        }

        &__title--plus {
            @media screen and (max-width: 768px) {
                padding-left: 0;
            }
        }

        &__title--jobs {
            padding-right: 0;
        }

        &__link:hover .document__title {
            color: $color4;
        }

        &__cta {
            width: 123px;
            white-space: nowrap;
            font-family: $font1;
            line-height: 1.7;
            font-weight: 700;
            color: $color1;
            transition: color 300ms $easeInOutCubic;
            font-size: 14px;
            text-transform: uppercase;
            margin-left: 50px;
            align-self: center;

            @media screen and (max-width: 600px) {
                margin-left: 30px;
                padding-bottom: 31px;
                align-self: start;
            }
        }

        &__link:hover .document__cta {
            color: $color4;
        }
    
        &__icon {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            transition: transform 300ms $easeInOutCubic ;
            background: #fff;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%,-50%);
            overflow: hidden;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                border: solid $color1 1px;
                transition: border 300ms $easeInOutCubic;
                border-radius: 50%;
                z-index: 1;
            }
    
            &::after {
                content: "";
                background: $color4;
                position: absolute;
                top: 100%;
                transition: top 300ms $easeInOutCubic;
                bottom: 0;
                right: 0;
                left: 0;
            }

            @media screen and (max-width: 768px) {
                width: 50px;
                height: 50px;
            }
        }

        &__link:hover .document__icon {
            &::after { top: 0%; }
            &::before { border: solid $color4 1px; }
        }
    
        &__svg {
            position: absolute;
            width: 14px;
            height: 14px;
            top: 50%;
            left: 50%;
            fill: $color1;
            transform: translate(-50%, -50%);
            transition: fill 300ms $easeInOutCubic;
            z-index: 10;
        }

        &__link:hover .document__svg {
            fill: #fff;
        }
        
        &__svg--upload {
            width: 10px;
            height: 10px;
        }
    }

    .document--jobs {
        @media screen and (max-width: 768px) {
            padding-left: 0;
        }
    }
}

// Document normal dans une rubrique
.documents-in-rubric {
    &.documents-no-padding {
        padding: 0;
    }

    .document {
        margin-bottom: 10px;
        
        &__link {
            display: block;
            position: relative;
            padding: 30px 110px 30px 40px;
            background: $color6;
            transition: background 300ms $easeOutCubic;

            &::after {
                content: "";
                width: 100%;
                height: 0%;
                transition: height 500ms $easeInOutCubic;
                background: $color7;
                position: absolute;
                bottom: 0;
                left: 0;
            }

            @media screen and (max-width: 600px) { padding: 15px 75px 15px 25px; }
        }
    
        &__link:hover {

            &::after {
                height: 100%;
            }
        }

        &__link--plus {
            padding: 0 75px 0 0;
            transition: padding 500ms $easeInOutCubic, box-shadow 300ms $easeInOutCubic;
            display: flex;
            flex-direction: row;
            align-items: center;

            @media screen and (max-width: 600px) {
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }

        &__img-container {
            width: 110px;
            height: 110px;
            position: relative;
            z-index: 10;

            @media screen and (max-width: 600px) {
                display: none;
            }
        }
        
        &__text {
            display: flex;
            flex-direction: column;
        }

        &__text--plus {
            margin-left: 30px;
            display: flex;
            flex-direction: column;
        }
        
        &__surtitle {
            font-family: $font1;
            line-height: 1.5;
            color: $color1;
            font-size: 12px;
            transition: color 300ms $easeInOutCubic;
            position: relative;
            z-index: 10;
        }

        &__link:hover .document__surtitle {
            color: #fff;
        }
        
        &__title {
            font: rem(23)/1 $font1;
            color: $color1;
            transition: color 300ms;
            line-height: 1.5;
            position: relative;
            z-index: 10;

            @media screen and (max-width: 500px) {
                font: rem(18)/1 $font1;
            }
        }
        
        &__link:hover .document__title {
            color: #fff;
        }

        &__circle {
            width: 60px;
            height: 60px;
            border: solid $color1 1px;
            border-radius: 50%;
            background: $color6;
            transition: background 300ms $easeInOutCubic;
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
            z-index: 10;

            @media screen and (max-width: 600px) {
                width: 25px;
                height: 25px;
            }
        }

        &__link:hover .document__circle {
            background: #fff;
        }
    
        &__svg {
            position: absolute;
            width: 14px;
            height: 14px;
            top: 50%;
            left: 50%;
            stroke: $color1;
            fill: $color1;
            transform: translate(-50%,-50%);
            z-index: 1;
            transition: fill 300ms;

            @media screen and (max-width: 600px) {
                width: 10px;
            }
        }
    
        &__svg-upload {
            width: 10px;
            height: auto;

            @media screen and (max-width: 600px) {
                width: 10px;
            }
        }
    }
}

// Document normal dans une rubrique
.documents-in-rubric.documents-in-rubric--plus {
    .document {
        &__flex {
            display: flex;
            align-items: center;
        }

        &__text {
            width: calc(100% - 130px);

            @media screen and (max-width: 1200px) { width: calc(100% - 155px); }
            @media screen and (max-width: 1024px) { width: calc(100% - 125px); }
            @media screen and (max-width: 768px)  { width: calc(100% - 95px);  }
            @media screen and (max-width: 600px)  { width: 100%; }
        }

        &__picture {
            width: 100px;
            height: 100px;
            margin-right: 30px;
            border-radius: 50%;
            overflow: hidden;

            @media screen and (max-width: 1200px) { width: 125px; height: 125px; }
            @media screen and (max-width: 1024px) { width: 100px; height: 100px; margin-right: 25px; }
            @media screen and (max-width: 768px)  { width: 75px;  height: 75px; margin-right: 20px; }
            @media screen and (max-width: 600px)  { display: none; }
        }

        &__subtitle {
            margin-bottom: 5px;
        }
    }
}

// Document dans une rubrique en tant que composante
.documents-in-rubric.documents-in-rubric--small {
    .document {
        max-width: 475px;

        &__link {
            padding-right: 30px;
        }

        &__svg {
            right: 0;
        }

        &__title {
            font: 700 rem(13)/1 $font1;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
}